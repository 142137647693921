import DynamicObject from 'opensooq-notification/dist/esm/dynamic-object';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { PointOfSales } from '../../helper/PointOfSales';
import { extractQueryParams } from '../../helpers/commonFunctions';

interface propsType {
    icons: string[],
    buttonText: string,
    deepLink: string,
    subTitle: string,
    title: string
}

export function BoostYourListings({ icons, buttonText,subTitle, title }: propsType) {
    let params = new URLSearchParams(window.location.search);
    let listingId = params.get("id"); // is the string "Jonathan"
    const Intl = useIntl();
    const handleRedirect = () => {
        const url = `${process.env.REACT_APP_ADD_POST}?flow=promoteFlow&post_id=${listingId}&pos=${PointOfSales.MY_LISTING_VIEW_POS_MANAGE_LISTING_PROMOTE}&cta= ''&viewType=Addon&return=''${window.location.href}&${extractQueryParams()}&actionSource=''`;
        window.location.href =url ;
    };
    return (
        <div className="notice d-flex flex-column bg-light-primary rounded border-primary border border-dashed p-5">
            <div className="mb-3 mb-md-0 fw-semibold">
                <h4 className="text-gray-900 fw-bold">{Intl.formatMessage({ id: title })} </h4>
                <div className="fs-8 text-gray-700 pe-7 mb-3">{Intl.formatMessage({ id: subTitle })}</div>
            </div>

            <div>
                <div style={{ width: '18.5rem' }} className='d-flex gap-3 align-items-center justify-content-center'>
                    {icons.map((icon, index) => {
                        const finalIconUrl = icon
                            .replace('{platform}', 'android')
                            .replace('{size}', 'xxxh');
                        return (
                            <img
                                key={index}
                                src={finalIconUrl}
                                alt={`Icon ${index}`}
                                width="37" // Adjust size
                                height="37"
                                style={{ objectFit: 'contain' }}
                            />
                        );
                    })}
                </div>
                <div style={{ width: '18.5rem' }} className='d-flex align-items-center justify-content-center'>
                    <button style={{ width: '58%' }} className="btn px-6 mt-4 btn-primary align-self-center text-nowrap" onClick={handleRedirect}>{buttonText}</button>
                </div>
            </div>


        </div>
    )
}