import {useState} from "react";
import {useIntl} from "react-intl";
import DynamicObject from "../../../../models/dynamic-object";
import {EDIT} from "../../../helper/Ctas";
import {editListing} from "../../../helpers/commonFunctions";
import {IframeModal} from "../../../layout/components/modals/IframeModal";
import styles from "./listingQuality.module.css";

type Props = {
    listing?: DynamicObject
    action: () => any
}

export function ListingQuality({listing, action}: Props) {
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const Intl = useIntl();

    const canEdit = () => {
        let canEdit = false;
        if (listing) {
            listing.ctas.map((cta: DynamicObject, index: number) => {
                if (cta.cta === EDIT) {
                    canEdit = true;
                }
            });
        }
        return canEdit
    }

    const isEnabled = () => {
        let isEnabled = false;
        if (listing) {
            listing.ctas.map((cta: DynamicObject, index: number) => {
                if (cta.cta === EDIT && cta.cta.enabled) {
                    isEnabled = true;
                }
            });
        }
        return isEnabled
    }

    const editName = () => {
        let editName = '';
        if (listing) {
            listing.ctas.map((cta: DynamicObject, index: number) => {
                if (cta.cta === EDIT) {
                    editName = cta.label
                }
            });
        }

        return editName
    }
    return <>{listing && <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
        <div className="card-header">
            <div className="card-title align-items-start flex-column">
                <h2>{Intl.formatMessage({id: "myListingView.text6"})}</h2>
                <div
                    className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{Intl.formatMessage({id: "myListingView.text7"})}</div>
            </div>
        </div>
        <div className="card-body pt-0">
            <div className="notice  rounded  border border-dashed p-6">
                <div className="d-flex   flex-column mt-3 pb-5">
                    <div className="py-1">
                        <span className="text-dark fs-1 fw-bold me-2">{listing.pqs.value}%</span>
                        <span
                            className="fw-semibold text-gray-800 fs-7">{Intl.formatMessage({id: "myListingView.text8"})} </span>
                    </div>
                    <div className="h-5px  w-100 bg-light mb-3">
                        <div className="bg-success rounded h-5px" style={{width: `${listing.pqs.value}%`}}></div>
                    </div>
                </div>
            </div>
            {listing?.pqs?.missing_items?.length > 0 && listing?.pqs?.missing_items && (
                <div className="mt-8">
                    {listing.pqs.missing_items.map((item: any, index: number) => (
                        <div key={index} className={`card p-2 mt-4 ${styles.customCard}`}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center fs-3 align-items-center me-3"
                                    style={{
                                        width: "23.5px",
                                        height: "23.5px",
                                        borderRadius: "50%",
                                        border: "2px solid black",
                                    }}>
                                    <i className="fas fa-exclamation fs-4" ></i>
                                </div>
                                <div className="text-gray-800 fs-5 fw-semibold">{item}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {canEdit() && <div className="d-flex mt-4 p-0 justify-content-end">
                <button className="btn btn-md btn-primary mt-3" disabled={isEnabled()} onClick={() => {editListing(listing,'edit')}}>{editName()}</button>
            </div>}
            <IframeModal show={showEditListingModal} id={listing?.listing_id} type={'edit'} handleClose={() => {
                setShowEditListingModal(false)
            }}/>
        </div>
    </div>}</>
}