import { useEffect, useState } from "react";

interface ButtonProps{
    action      ?:    (params?: any) => any
    className   ?:    string
    text        ?:    any
    disabled    ?:    boolean
    setData     ?:    number | string
    style       ?:    {fontColor?: string, bgColor?: string, border?: string}
    widthValue       ?:    string
}

const Button = (props : ButtonProps) => {
  const [isDisabledBtn,setIsDisabledBtn] = useState(false)
  useEffect(() => {
    setIsDisabledBtn(props && props.disabled ? props.disabled : false);
  },[props.disabled]);
  return (
    <>
        <button data-id={props.setData} disabled={isDisabledBtn} className={`btn ${props.className}`} onClick={props.action} style={{backgroundColor: props.style?.bgColor, color: props.style?.fontColor, border: props.style?.border , width:props.widthValue , height:"39.5px" , borderRadius:"8px"}}>{props.text}</button>
    </>
  );
};

export { Button };
