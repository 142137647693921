import { PopupChat, useChatFunctions } from 'opensooq-chat';
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useIntl } from "react-intl";
import Sheet from 'react-modal-sheet';
import { ActionDimensions } from "../../logging/dimensions/ActionDimensions";
import { TechDimensions } from "../../logging/dimensions/TechDimensions";
import DynamicObject from "../../models/dynamic-object";
import { KTSVG, toAbsoluteUrl } from "../helpers";
import { checkForListingIdInCookiesIfExist } from "../helpers/LastContacted";
import { getCookies, isDeviceMobile, shorten } from "../helpers/commonFunctions";
import { useLang } from "../i18n/OpenSooqi18n";
import { ContactAddon } from "../interfaces/ListingInterface";
import { Button } from "../layout/components/opensooq/Button";
import './ChatPopup.css';
import {logMessageMethodChat, logMessageMethodSMS, logMessageMethodWhatsapp} from '../../logging/helpers/commonLoggingFunctions';
import { AuthContext } from '../i18n/AuthProvider';
import leadLoggingApi from '../../api/logging/leadLogging.api';
import getListingInfoV3Api from '../../api/listing/get-listing-info-v3.api';
import getListingPhoneV3Api from '../../api/listing/getListingPhoneV3.api';


interface ChatPopupProps {
    children?: React.ReactNode;
    contactAddon?: ContactAddon
    roomData?: DynamicObject
    post_url?: string
    listing: DynamicObject
    setShowLastContacted : (setShowLastContacted : boolean) => void
    smsPhone?:string
    whatsPhone?:string
}

const DEFAULT_ROOM = undefined
const ChatPopup = ({ post_url, roomData, contactAddon,listing,setShowLastContacted,smsPhone,whatsPhone}: ChatPopupProps): JSX.Element => {
    const [room, setRoom] = useState<any>(DEFAULT_ROOM)
    const [showChatOptions, setShowChatOptions] = useState<boolean>(false)
    const [isClicked, setIsClicked] = useState(false);
    let optionsCount = useRef<number>(0)
    let chatEnabled = contactAddon?.chat?.enabled
    let smsEnabled = contactAddon?.sms?.enabled
    let whatsappEnabled = contactAddon?.whatsapp?.enabled
    const {userIdentity} = useContext(AuthContext)
    const [whatsAppNumber,setWhatsAppNumber] = useState(whatsPhone);
    const [smsPhoneNumber,setSmsPhoneNumber] = useState(smsPhone);
    const Intl = useIntl()
    const {createRoom} = useChatFunctions()

    const closeModalHandler = () => {
        setRoom(DEFAULT_ROOM)
    }

    const createRoomHandler = (data: DynamicObject) => {
        checkForListingIdInCookiesIfExist(listing,null)
        setShowLastContacted(true)
        setShowChatOptions(false)
        leadLoggingApi('lead',"Chat", "Post", listing.id, ActionDimensions.getInstance().currScreen)
        createRoom(data as any).then((room) => {
            setRoom(room)
        })
    }

    const openChatOptions = () => {
        if(whatsPhone){
            setWhatsAppNumber(whatsPhone)
            setSmsPhoneNumber(smsPhone)
            openModel()
        }else{
            getListingInfoV3Api(listing.id).then(listingInfo => {
                const payload = listingInfo.data
                if(payload && payload.listing.masked_local_phone && (getCookies().userInfo?.id?.toString() !== payload.listing?.member_id?.toString())) {
                    getListingPhoneV3Api({"reveal_key" : payload.listing.listing_reveal_phone_key, "type" : "post"}).then(phoneData => {
                        setWhatsAppNumber(phoneData.data.whatsapp_phone_number);
                        setSmsPhoneNumber(phoneData.data.call_phone_number);
                        openModel();
                    })
                }
            })
        }
    }

    const openModel = () => {
        if(optionsCount.current == 1 && contactAddon.chat?.enabled){
            logMessageMethodChat(
                {
                    id: listing.listing_id,
                    service: listing.service_name && listing.service_name.length > 0 ? listing.service_name[listing.service_name.length-1]?.icon : '',
                    category: { label: listing.category_reporting_name },
                    city:{name: listing.city_label_en},
                    country: listing.country_abbreviation,
                    neighborhoodName: listing.neighborhood_name_en ?? '',
                    subCategory: listing.subcategory_reporting_name
                },
                listing.members_id == userIdentity.id,
                false,
                'Yes'
            );
            return  createRoomHandler(roomData)
        }
        return setShowChatOptions(true)
    }

    const closeChatOptions = () => {
        setShowChatOptions(false)
    }

    const whatsappForward = () => {
        setIsClicked(true);
        checkForListingIdInCookiesIfExist(listing,null)
        setShowLastContacted(true)
        logMessageMethodWhatsapp(
            {
                id: listing.listing_id,
                service: listing.service_name && listing.service_name.length > 0 ? listing.service_name[listing.service_name.length-1]?.icon : '',
                category: { label: listing.category_reporting_name },
                city:{name: listing.city_label_en},
                country: listing.country_abbreviation,
                neighborhoodName: listing.neighborhood_name_en ?? '',
                subCategory: listing.subcategory_reporting_name
            },
            listing.members_id == userIdentity.id,
            'Yes'
        );
        let url = {
            links : [
                `${post_url}#utm_source=opensooq&utm_medium=replies&utm_campaign=chat_whatsapp_${ActionDimensions.getInstance().currScreen}_from_${TechDimensions.getInstance().platform}`
            ]
        };
        leadLoggingApi('lead',"WhatsApp", "Post", listing.id, ActionDimensions.getInstance().currScreen)
        shorten(url).then((sortenUrl) => {
            let message = contactAddon.whatsapp.message.replace('{Link}', sortenUrl.data.links[0])
            setTimeout(()=>{
                window.open(`https://wa.me/${whatsAppNumber}?text=${message}`, '_self');
                setShowChatOptions(false)
            }, 250)
        }).finally(()=>{
            setIsClicked(false);
        })
    }

    const handleSMS = () => {
        if(isClicked)
            return;
        setIsClicked(true);
        checkForListingIdInCookiesIfExist(listing,null)
        setShowLastContacted(true)
        logMessageMethodSMS(
            {
                id: listing.listing_id,
                service: listing.service_name && listing.service_name.length > 0 ? listing.service_name[listing.service_name.length-1]?.icon : '',
                category: { label: listing.category_reporting_name },
                city:{name: listing.city_label_en},
                country: listing.country_abbreviation,
                neighborhoodName: listing.neighborhood_name_en ?? '',
                subCategory: listing.subcategory_reporting_name
            },
            listing.members_id == userIdentity.id,
            'Yes'
        );
        let url = {
            links : [
                `${post_url}#utm_source=opensooq&utm_medium=replies&utm_campaign=chat_sms_${ActionDimensions.getInstance().currScreen}_from_${TechDimensions.getInstance().platform}`
            ]
        }
        leadLoggingApi('lead',"SMS", "Post", listing.id, ActionDimensions.getInstance().currScreen)
        shorten(url).then((sortenUrl) => {
            let message = contactAddon.sms.message.replace('{Link}', sortenUrl.data.links[0])
            window.open(`sms://${smsPhoneNumber}?&body=${message}`, '_self');
            setShowChatOptions(false)
        }).finally(()=>{
            setIsClicked(false);
        })
    }

    const lang = useLang();
    useEffect(() => {
        optionsCount.current = (chatEnabled ? 1 : 0) + (smsEnabled ? 1 : 0) + (whatsappEnabled ? 1 : 0)
    }, [chatEnabled, smsEnabled, whatsappEnabled])

    const ChatButton = (buttonProps: { onCreateRoom: (roomData: DynamicObject) => void }) : JSX.Element => {
        const clickEventHandler = () => {
            if(isClicked)
                return;
            setIsClicked(true);
            logMessageMethodChat(
                {
                    id: listing.listing_id,
                    service: listing.service_name && listing.service_name.length > 0 ? listing.service_name[listing.service_name.length-1]?.icon : '',
                    category: { label: listing.category_reporting_name },
                    city:{name: listing.city_label_en},
                    country: listing.country_abbreviation,
                    neighborhoodName: listing.neighborhood_name_en ?? '',
                    subCategory: listing.subcategory_reporting_name
                },
                listing.members_id == userIdentity.id,
                optionsCount.current == 1 ? false : true,
                'Yes'
            );
            buttonProps.onCreateRoom(roomData)
            setIsClicked(false)
        }
        return <Button action={clickEventHandler} className={`btn ${isDeviceMobile()?"mb-2 p-2":"m-2"} w-100 p-0 ps-2 pe-2 border rounded-3 shadow-sm`}

        text={<div>
            <div className="d-flex bd-highlight align-middle align-items-center">
                <div className=""> <img className="me-1" width="30" height="30" src={toAbsoluteUrl('/media/svg/social-logos/chat.svg')} alt="feature"/></div>
                <div className="p-2"><span className="fs-3">{Intl.formatMessage({id: 'chat'})}</span></div>
                <div className="ms-auto p-2">
                    <i className={`fa ${lang == "en"? "fa-chevron-right": "fa-chevron-left"} p-3`}></i>
                </div>
            </div>
       </div>}/>

    }

    const SMSButton = () : JSX.Element =>  (<Button action={handleSMS} className={`btn ${isDeviceMobile()?"mb-2 mt-2 p-2":"m-2"} w-100 p-0 ps-2 pe-2 border rounded-3 shadow-sm`}
        text={<div>
                <div className="d-flex bd-highlight align-middle align-items-center">
                    <div className=""> <img className="me-1" width="25" height="25" src={toAbsoluteUrl('/media/svg/social-logos/chat-sms.svg')} alt="feature"/></div>
                    <div className="p-2"><span  className="fs-3">{Intl.formatMessage({id: 'sms'})}</span></div>
                    <div className="ms-auto p-2"><i className={`fa ${lang == "en"? "fa-chevron-right": "fa-chevron-left"} p-3`}></i></div>
                </div>
        </div>}/>)

    const WhatsAppButton = () : JSX.Element => (<Button action={whatsappForward} className={`btn ${isDeviceMobile()?"mb-2 mt-2 p-2":"m-2"} w-100 p-0 ps-2 pe-2 border rounded-3 shadow-sm`}
            text={<div>
                    <div className="d-flex bd-highlight align-middle align-items-center">
                        <div className=""> <img className="me-1" width="25" height="25" src={toAbsoluteUrl('/media/svg/social-logos/whatsapp.svg')} alt="feature"/></div>
                        <div className="p-2"><span className="fs-3">{Intl.formatMessage({id: 'whatsapp'})}</span></div>
                        <div className="ms-auto p-2">
                            <i className={`fa ${lang == "en"? "fa-chevron-right": "fa-chevron-left"} p-3`}></i>
                        </div>
                    </div>
            </div>}/>)


    const SharedBodyContent = () => {
        return (<>
                <div className={`${isDeviceMobile()? "" :"modal-header "} p-5 d-flex flex-row justify-content-space-between `}>
                    <div className='btn btn-sm btn-active-color-primary' onClick={closeChatOptions}>
                        <i className="fa-solid fa-close fs-1" ></i>
                    </div>
                    <span className=" text flex-grow-1 text-center fs-1 ">{Intl.formatMessage({id: 'listing.sendMessage'})}</span>
                </div>
                <div className={`modal-body ${isDeviceMobile()?"m-3":""}`}>
                    { contactAddon && chatEnabled && <ChatButton onCreateRoom={createRoomHandler}/>}
                    { contactAddon && whatsappEnabled &&  <WhatsAppButton />}
                    { contactAddon && smsEnabled && <SMSButton/> }
                </div>
        </>
        )
    }
    const DeviceModal = useMemo(() => {
        const handleSheetClick = (e: any) => {
            e.stopPropagation();
            closeChatOptions()
        }
        return (
            <>
                { isDeviceMobile()?
                <Sheet detent="content-height" isOpen={showChatOptions} onClose={closeChatOptions} >
                    <Sheet.Container  className="pb-7">
                        <SharedBodyContent />
                    </Sheet.Container>
                    <Sheet.Backdrop onTap={(e) => handleSheetClick(e)}  />
                </Sheet>:
                 <Modal dialogClassName='modal-dialog modal-dialog-centered' show={showChatOptions} onHide={closeChatOptions}>
                    <SharedBodyContent />
                </Modal>}
            </>
        )
    }, [showChatOptions])

    const ModalHandleButton = () => {
        switch (optionsCount.current) {
            case 0:
                return <></>
            case 1:
                if (chatEnabled) {
                    return <Button action={openChatOptions}  className={`btn p-2 btn-sm whiteBtn fs-6 ${isMobile ? 'flex-1' : ''}`}
                        text={<><img className="me-1" width="22" height="22" src={toAbsoluteUrl('/media/svg/social-logos/chat.svg')} alt="feature"/> {Intl.formatMessage({id: 'chat'})}</>}/>
                } else if (smsEnabled) {
                    return <Button action={handleSMS}  className={`btn p-2 btn-sm whiteBtn fs-6 ${isMobile ? 'flex-1' : ''}`}
                        text={<><img className="me-1" width="20" height="20" src={toAbsoluteUrl('/media/svg/social-logos/chat-sms.svg')} alt="feature"/> {Intl.formatMessage({id: 'sms'})}</>}/>
                }  if (whatsappEnabled) {
                        return <Button action={whatsappForward} style={{border: "1px solid black"}}  className={`p-2 fs-6 text-black ${isMobile ? 'flex-1' : ''}`}
                            text={<><img className="me-1" width="18" height="18" src={toAbsoluteUrl('/media/svg/social-logos/whatsapp.svg')} alt="feature"/> {Intl.formatMessage({id: 'whatsapp'})}</>}/>
                } else {
                    <></>
                }
               return <></>
            default:
                return <Button action={openChatOptions}  className={`btn p-2 btn-sm whiteBtn fs-6 ${isMobile ? 'flex-1' : ''}`}
                    text={<><img className="" src={toAbsoluteUrl('/media/svg/social-logos/chatGroup.svg')} alt="feature"/> {Intl.formatMessage({id: 'chat'})}</>}/>
        }
    }
    return (
        <>
            {ModalHandleButton()}
            {DeviceModal}
            <Modal id='iframe' tabIndex={-1} aria-hidden='true'
                   dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen' show={!!room}>
                <div className='modal-header p-5'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModalHandler}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg'/>
                    </div>
                </div>
                <div className='modal-body popup' style={{overflowY: "hidden"}}>
                    {room && <PopupChat newChat={room} onClear={closeModalHandler}/> }
                </div>
            </Modal>
        </>
    )
}

export default ChatPopup