import {PopupChat, useChatFunctions} from 'opensooq-chat'
import React, {useContext, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {isMobile} from 'react-device-detect'
import {useIntl} from 'react-intl'
import Sheet from 'react-modal-sheet'
import {AuthContext} from '../../i18n/AuthProvider'
import {useLang} from '../../i18n/OpenSooqi18n'
import DynamicObject from '../../../models/dynamic-object'
import {checkForListingIdInCookiesIfExist} from '../../helpers/LastContacted'
import leadLoggingApi from '../../../api/logging/leadLogging.api'
import {ActionDimensions} from '../../../logging/dimensions/ActionDimensions'
import {getCookies, isDeviceMobile, shorten} from '../../helpers/commonFunctions'
import getListingPhoneV3Api from '../../../api/listing/getListingPhoneV3.api'
import {
  logMessageMethodChat,
  logMessageMethodSMS,
  logMessageMethodWhatsapp,
} from '../../../logging/helpers/commonLoggingFunctions'
import {TechDimensions} from '../../../logging/dimensions/TechDimensions'
import {Button} from '../../layout/components/opensooq/Button'
import {KTSVG, toAbsoluteUrl} from '../../helpers'
import {Config} from '../../interfaces/favoriteInterface'
import styles from '../../layout/components/opensooq/FavoriteListingCell/favoriteListingCell.module.css'

interface ChatPopupProps {
  children?: React.ReactNode
  contactAddon?: any
  roomData?: DynamicObject
  post_url?: string
  listing: DynamicObject
  setShowLastContacted: (setShowLastContacted: boolean) => void
  smsPhone?: string
  whatsPhone?: string
  configData?: Config
  widthValue?: string
  enableChatByDefault?: boolean
}

const DEFAULT_ROOM = undefined

const ListingsChatPopUp = ({
  post_url,
  roomData,
  contactAddon,
  listing,
  setShowLastContacted,
  smsPhone,
  whatsPhone,
  configData,
  widthValue,
  enableChatByDefault,
}: ChatPopupProps): JSX.Element => {
  const [room, setRoom] = useState<any>(DEFAULT_ROOM)
  const [showChatOptions, setShowChatOptions] = useState<boolean>(false)
  const [isClicked, setIsClicked] = useState(false)
  let addonsOptionsCount = useRef<number>(0)
  let chatEnabled = contactAddon?.chat?.enabled || contactAddon?.contact?.chat || false
  let smsEnabled = contactAddon?.sms?.enabled || contactAddon?.contact?.sms?.enabled || false
  let whatsappEnabled = contactAddon?.whatsapp?.enabled || contactAddon?.contact?.whatsapp?.enabled || false
  const isListingDisActive = listing?.is_active_listing && listing?.is_active_listing === false || false;

  const {userIdentity} = useContext(AuthContext)
  const [whatsAppNumber, setWhatsAppNumber] = useState(whatsPhone)
  const [smsPhoneNumber, setSmsPhoneNumber] = useState(smsPhone)
  const Intl = useIntl()
  const lang = useLang()
  const {createRoom} = useChatFunctions()

  const closeModalHandler = () => {
    setRoom(DEFAULT_ROOM)
  }

  const createRoomHandler = (data: DynamicObject) => {
    checkForListingIdInCookiesIfExist(listing, null)
    setShowLastContacted(true)
    setShowChatOptions(false)
    leadLoggingApi('lead', 'Chat', 'Post', listing.id, ActionDimensions.getInstance().currScreen)
    createRoom(data as any).then((room) => setRoom(room))
  }

  const openChatOptions = () => {
    if (whatsPhone) {
      setWhatsAppNumber(whatsPhone)
      setSmsPhoneNumber(smsPhone)
      openModel()
    } else {
      if ((listing?.phone_number || listing?.phone) &&
        getCookies().userInfo?.id?.toString() !== listing?.member_id?.toString()
      ) {
        getListingPhoneV3Api({
          reveal_key: listing.phone_reveal_key,
          type: 'post',
        }).then((phoneData) => {
          setWhatsAppNumber(phoneData.data.whatsapp_phone_number)
          setSmsPhoneNumber(phoneData.data.call_phone_number)
          openModel()
        })
      }
    }
  }

  const openModel = () => {
    if (
      addonsOptionsCount.current == 1 &&
      (contactAddon.chat?.enabled || contactAddon?.contact?.chat)
    ) {
      logMessageMethodChat(
        {
          id: listing?.listing_id,
          service:
            listing.service_name && listing.service_name?.length > 0
              ? listing.service_name[listing.service_name?.length - 1]?.icon
              : '',
          category: {label: listing.category_reporting_name},
          city: {name: listing.city_label_en},
          country: listing.country_abbreviation,
          neighborhoodName: listing.neighborhood_name_en ?? '',
          subCategory: listing.subcategory_reporting_name,
        },
        listing?.members_id == userIdentity?.id,
        false,
        'Yes'
      )
      return createRoomHandler(roomData)
    }
    return setShowChatOptions(true)
  }

  const closeChatOptions = () => {
    setShowChatOptions(false)
  }

  const whatsappForward = () => {
    setIsClicked(true)
    checkForListingIdInCookiesIfExist(listing, null)
    setShowLastContacted(true)
    logMessageMethodWhatsapp({
        id: listing?.listing_id,
        service:
          listing.service_name && listing.service_name?.length > 0
            ? listing.service_name[listing.service_name?.length - 1]?.icon
            : '',
        category: {label: listing.category_reporting_name},
        city: {name: listing?.city_label_en},
        country: listing.country_abbreviation,
        neighborhoodName: listing.neighborhood_name_en ?? '',
        subCategory: listing.subcategory_reporting_name,
      }, listing?.members_id == userIdentity?.id,'Yes')

    let url = {
      links: [
        `${post_url}#utm_source=opensooq&utm_medium=replies&utm_campaign=chat_whatsapp_${
          ActionDimensions.getInstance().currScreen
        }_from_${TechDimensions.getInstance().platform}`,
      ],
    }

    leadLoggingApi(
      'lead',
      'WhatsApp',
      'Post',
      listing?.id,
      ActionDimensions.getInstance().currScreen
    )

    shorten(url).then((shortenUrl) => {
        let message = contactAddon?.whatsapp?.message.replace('{Link}', shortenUrl?.data?.links[0]) ||
          configData?.addons_message?.replace('{Link}', shortenUrl?.data?.links[0])
        setTimeout(() => {
          window.open(`https://wa.me/${whatsAppNumber}?text=${message}`, '_self')
          setShowChatOptions(false)
        }, 250)
      }).finally(() => {
        setIsClicked(false)
      })
  }

  const handleSMS = () => {
    if (isClicked) return
    setIsClicked(true)
    checkForListingIdInCookiesIfExist(listing, null)
    setShowLastContacted(true)
    logMessageMethodSMS({
        id: listing.listing_id,
        service:
          listing.service_name && listing.service_name.length > 0
            ? listing.service_name[listing.service_name.length - 1]?.icon
            : '',
        category: {label: listing.category_reporting_name},
        city: {name: listing.city_label_en},
        country: listing.country_abbreviation,
        neighborhoodName: listing.neighborhood_name_en ?? '',
        subCategory: listing.subcategory_reporting_name,
      },
      listing.members_id == userIdentity.id,
      'Yes'
    )
    let url = {
      links: [
        `${post_url}#utm_source=opensooq&utm_medium=replies&utm_campaign=chat_sms_${
          ActionDimensions.getInstance().currScreen
        }_from_${TechDimensions.getInstance().platform}`,
      ],
    }
    leadLoggingApi('lead', 'SMS', 'Post', listing.id, ActionDimensions.getInstance().currScreen)
    shorten(url).then((shortenUrl) => {
        let message =
          contactAddon?.sms?.message.replace('{Link}', shortenUrl?.data?.links[0]) ||
          configData?.addons_message?.replace('{Link}', shortenUrl?.data?.links[0])
        window.open(`sms://${smsPhoneNumber}?&body=${message}`, '_self')
        setShowChatOptions(false)
      }).finally(() => {
        setIsClicked(false)
      })
  }

  // Check to appear the addons buttons
  addonsOptionsCount.current = 0
  if (smsEnabled) {
    addonsOptionsCount.current += 1
  }

  if (whatsappEnabled) {
    addonsOptionsCount.current += 1
  }

  if (chatEnabled) {
    addonsOptionsCount.current += 1
  } else if (!chatEnabled && enableChatByDefault && addonsOptionsCount.current === 0) {
    addonsOptionsCount.current += 1
  }

  const ChatButton = (buttonProps: {
    onCreateRoom: (roomData: DynamicObject) => void
  }): JSX.Element => {
    const clickEventHandler = () => {
      if (isClicked) return
      setIsClicked(true)
      logMessageMethodChat({
          id: listing.listing_id,
          service:
            listing.service_name && listing.service_name.length > 0
              ? listing.service_name[listing.service_name.length - 1]?.icon
              : '',
          category: {label: listing.category_reporting_name},
          city: {name: listing.city_label_en},
          country: listing.country_abbreviation,
          neighborhoodName: listing.neighborhood_name_en ?? '',
          subCategory: listing.subcategory_reporting_name,
        },
        listing?.members_id == userIdentity?.id,
        addonsOptionsCount.current != 1,
        'Yes'
      )
      buttonProps.onCreateRoom(roomData)
      setIsClicked(false)
    }

    return (
      <Button
        action={clickEventHandler}
        className={`btn ${
          isDeviceMobile() ? 'mb-2 p-2' : 'm-2'
        } p-0 ps-2 pe-2 border rounded-3 shadow-sm width-100`}
        text={
          <div>
            <div className='d-flex bd-highlight align-middle align-items-center'>
              <div className=''>
                {' '}
                <img
                  className='me-1'
                  width='30'
                  height='30'
                  src={toAbsoluteUrl('/media/svg/social-logos/chat.svg')}
                  alt='feature'
                />
              </div>
              <div className='p-2'>
                <span className='fs-3'>{Intl.formatMessage({id: 'chat'})}</span>
              </div>
              <div className='ms-auto p-2'>
                <i
                  className={`fa ${lang == 'en' ? 'fa-chevron-right' : 'fa-chevron-left'} p-3`}
                ></i>
              </div>
            </div>
          </div>
        }
      />
    )
  }

  const SMSButton = (): JSX.Element => (
    <Button
      action={handleSMS}
      className={`btn ${
        isDeviceMobile() ? 'mb-2 mt-2 p-2' : 'm-2'
      } w-100 p-0 ps-2 pe-2 border rounded-3 shadow-sm`}
      text={
        <div>
          <div className='d-flex bd-highlight align-middle align-items-center'>
            <div className=''>
              {' '}
              <img
                className='me-1'
                width='25'
                height='25'
                src={toAbsoluteUrl('/media/svg/social-logos/chat-sms.svg')}
                alt='feature'
              />
            </div>
            <div className='p-2'>
              <span className='fs-3'>{Intl.formatMessage({id: 'sms'})}</span>
            </div>
            <div className='ms-auto p-2'>
              <i className={`fa ${lang == 'en' ? 'fa-chevron-right' : 'fa-chevron-left'} p-3`}></i>
            </div>
          </div>
        </div>
      }
    />
  )

  const WhatsAppButton = (): JSX.Element => (
    <Button
      action={whatsappForward}
      className={`btn ${
        isDeviceMobile() ? 'mb-2 mt-2 p-2' : 'm-2'
      } w-100 p-0 ps-2 pe-2 border rounded-3 shadow-sm`}
      text={
        <div>
          <div className='d-flex bd-highlight align-middle align-items-center'>
            <div>
              {' '}
              <img
                className='me-1 ms-1'
                width='23'
                height='23'
                src={toAbsoluteUrl('/media/svg/social-logos/whatsapp.svg')}
                alt='feature'
              />
            </div>
            <div className='p-2'>
              <span className='fs-3'>{Intl.formatMessage({id: 'whatsapp'})}</span>
            </div>
            <div className='ms-auto p-2'>
              <i className={`fa ${lang == 'en' ? 'fa-chevron-right' : 'fa-chevron-left'} p-3`}></i>
            </div>
          </div>
        </div>
      }
    />
  )

  const SharedBodyContent = () => {
    return (
      <>
        <div
          className={`${
            isDeviceMobile() ? '' : 'modal-header '
          } p-5 d-flex flex-row justify-content-space-between `}
        >
          <div className='btn btn-sm btn-active-color-primary' onClick={closeChatOptions}>
            <i className='fa-solid fa-close fs-1'></i>
          </div>
          <span className=' text flex-grow-1 text-center fs-1 '>
            {Intl.formatMessage({id: 'listing.sendMessage'})}
          </span>
        </div>
        <div className={`modal-body ${isDeviceMobile() ? 'm-3' : ''}`}>
          {contactAddon && chatEnabled && <ChatButton onCreateRoom={createRoomHandler} />}
          {contactAddon && whatsappEnabled && <WhatsAppButton />}
          {contactAddon && smsEnabled && <SMSButton />}
        </div>
      </>
    )
  }
  const DeviceModal = useMemo(() => {
    const handleSheetClick = (e: any) => {
      e.stopPropagation()
      closeChatOptions()
    }
    return (
      <>
        {isDeviceMobile() ? (
          <Sheet detent='content-height' isOpen={showChatOptions} onClose={closeChatOptions}>
            <Sheet.Container className='pb-7'>
              <SharedBodyContent />
            </Sheet.Container>
            <Sheet.Backdrop onTap={(e) => handleSheetClick(e)} />
          </Sheet>
        ) : (
          <Modal
            dialogClassName='modal-dialog modal-dialog-centered'
            show={showChatOptions}
            onHide={closeChatOptions}
          >
            <SharedBodyContent />
          </Modal>
        )}
      </>
    )
  }, [showChatOptions])

  const HandleAddonsButtonModal = () => {
    switch (addonsOptionsCount.current) {
      case 0:
        return <></>
      case 1:
        if (chatEnabled) {
          return (
            <Button
              action={openChatOptions}
              className={
                isListingDisActive
                  ? `rounder text-hover-white d-flex align-items-center justify-content-center fw-bold ${styles.disabledChatBtn}`
                  : `btn p-2 btn-sm ${styles.addonBtn} fs-6 ${isMobile ? 'flex-1' : ''}`
              }
              text={
                <>
                  <img
                    className='me-1'
                    width='27'
                    height='25'
                    src={toAbsoluteUrl(
                      isListingDisActive
                        ? '/media/svg/social-logos/disabledChat.svg'
                        : '/media/svg/social-logos/chat.svg'
                    )}
                    alt='feature'
                  />
                  {Intl.formatMessage({id: 'chat'})}
                </>
              }
              widthValue={widthValue}
            />
          )
        } else if (smsEnabled) {
          return (
            <Button
              action={handleSMS}
              className={
                isListingDisActive
                  ? `rounder text-hover-white d-flex align-items-center justify-content-center fw-bold ${styles.disabledChatBtn}`
                  : `btn p-2 btn-sm ${styles.addonBtn} fs-6 ${isMobile ? 'flex-1' : ''}`
              }
              text={
                <>
                  <img
                    className='me-1'
                    width='20'
                    height='20'
                    src={toAbsoluteUrl(
                      isListingDisActive
                        ? '/media/svg/social-logos/disabledSms.svg'
                        : '/media/svg/social-logos/chat-sms.svg'
                    )}
                    alt='feature'
                  />
                  {Intl.formatMessage({id: 'sms'})}
                </>
              }
              widthValue={widthValue}
            />
          )
        } else if (whatsappEnabled) {
          return (
            <Button
              action={whatsappForward}
              style={{border: '1px solid black'}}
              className={
                isListingDisActive
                  ? `text-hover-white d-flex align-items-center justify-content-center fw-bold gap-1 ${styles.disabledChatBtn}`
                  : `p-2 fw-bold d-flex align-items-center justify-content-center ${
                      styles.whiteBg
                    } gap-1 ${isMobile ? 'flex-1' : ''}`
              }
              text={
                <>
                  <img
                    className='me-1'
                    width='18'
                    height='18'
                    src={toAbsoluteUrl(
                      isListingDisActive
                        ? '/media/svg/social-logos/disabledWhatsApp.svg'
                        : '/media/svg/social-logos/whatsapp.svg'
                    )}
                    alt='feature'
                  />
                  <span className='fs-5'>{Intl.formatMessage({id: 'whatsapp'})}</span>
                </>
              }
              widthValue={widthValue}
            />
          )
        } else {
          return (
            <button
              className={`rounder text-hover-white d-flex align-items-center fw-bold justify-content-center ${styles.disabledChatBtn}`}
            >
              <img
                className='me-1'
                width='27'
                height='25'
                src={toAbsoluteUrl('/media/custom/disabledChat.png')}
                alt='feature'
              />
              <span>{Intl.formatMessage({id: 'chat'})}</span>
            </button>
          )
        }

      default:
        return (
          <Button
            action={openChatOptions}
            className={
              isListingDisActive
                ? `rounder text-hover-white d-flex align-items-center justify-content-center gap-1 fw-bold ${styles.disabledChatBtn}`
                : `btn p-2 btn-sm d-flex align-items-center justify-content-center gap-1 ${
                    styles.addonBtn
                  } fs-6 ${isMobile ? 'flex-1' : ''}`
            }
            widthValue={widthValue}
            text={
              <>
                <img
                  className=''
                  src={toAbsoluteUrl(
                    isListingDisActive
                      ? '/media/svg/social-logos/disabledChatGroup.svg'
                      : '/media/svg/social-logos/chatGroup.svg'
                  )}
                  alt='feature'
                />
                <span>{Intl.formatMessage({id: 'chat'})}</span>
              </>
            }
          />
        )
    }
  }

  return (
    <>
      {HandleAddonsButtonModal()}
      {DeviceModal}
      <Modal
        id='iframe'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen'
        show={!!room}
      >
        <div className='modal-header p-5'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModalHandler}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body popup' style={{overflowY: 'hidden'}}>
          {room && <PopupChat newChat={room} onClear={closeModalHandler} />}
        </div>
      </Modal>
    </>
  )
}

export default ListingsChatPopUp
