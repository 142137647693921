import  {FC, useEffect} from 'react'
import { useIntl } from 'react-intl'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import { PageLink, PageTitle } from '../../app/layout/core';
import { MyListingView } from '../modules/listing/MyListingView';
import { Drafts } from '../modules/listing/Drafts';
import { FollowingAds } from '../modules/listing/FollowingAds';
import { JobsAppliedFor } from '../modules/listing/JobsAppliedFor';
import { RecentlyViewedListings } from '../modules/listing/RecentlyViewedListings/RecentlyViewedListings';
import { ListingStats } from '../modules/listing/ListingStats';
import { LeadManagement } from '../common/LeadManagement';
import { SavedSearch } from '../modules/listing/SavedSearch';
import { RecentSearch } from '../modules/listing/RecentSearch';
import { ListingActionsIntegration } from '../modules/listing/ListingActionsIntegration';
import { CompleteListing } from '../modules/listing/CompleteListing';
import { ActivateListing } from '../modules/listing/ActivateListing';
import { CloneListing } from '../modules/listing/CloneListing';
import { Applicants } from '../modules/job/Applicants';
import { FavoriteListing } from '../modules/listing/FavoriteListings/FavoriteListing';
import { MyListings } from '../modules/listing/my-listings/MyListings';
import ChatRedirect from '../modules/ChatRedirect';
import { ApplicantCVFullView } from '../modules/accounts/cv/ApplicantCVFullView';
const history:string[] = [];

const ListingRoutes: FC = () => {
    const Intl = useIntl();
    const location = useLocation()
    const ads: Array<PageLink> = [
        {
            title: Intl.formatMessage({id: 'menu.listing'}),
            path: '/listings/my-listings',
            isSeparator: false,
            isActive: true,
        }
    ]

    useEffect(()=>{
      if(history.length == 2){
        history.shift();
      }
      history.push(location.pathname)
    }, [location]);
    
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='/error/404' state= {{ 'currentPath': window.location.pathname, 'previousPath' : history.length < 1 ? '' : history[history.length - 1] }} />} />
            <Route path='my-listings' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'menu.listing.myListing'})}</PageTitle> <MyListings /> </> } />
            <Route path='my-listings/listing-view' element={<> <PageTitle breadcrumbs={ads} hasToolTip={true}>{Intl.formatMessage({id: 'menu.listing.myListingView'})}</PageTitle> <MyListingView /> </> }/>
            <Route path='my-listings/listing-stats' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'adViewsAndStats'})}</PageTitle>  <ListingStats /></> } />
            <Route path='my-listings/listing-leads' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id : "leads"})}</PageTitle>  <LeadManagement/></> } />
            <Route path='my-listings/draft-listings' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'menu.listing.drafts'})}</PageTitle> <Drafts /> </> } /> <Route/>
            <Route path='my-listings/ApplicantCVFullView/:id' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'menu.listing.applicants'})}</PageTitle> <ApplicantCVFullView /> </> } /> <Route/>
            <Route path='demand/favorite-listings' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'menu.listing.favorites'})}</PageTitle> <FavoriteListing /> </> } />
            <Route path='demand/recently-viewed' element={<> <PageTitle breadcrumbs={ads} hasToolTip={true}>{Intl.formatMessage({id: 'menu.listing.recently'})}</PageTitle> <RecentlyViewedListings /> </> }/>
            <Route path='demand/following-listings' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'menu.listing.followings'})}</PageTitle> <FollowingAds /> </> } />
            <Route path='demand/job-applications' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'menu.listing.jobApplications'})}</PageTitle> <JobsAppliedFor /> </> } />
            <Route path='searches/saved-search' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'menu.listing.savedSearch'})}</PageTitle> <SavedSearch /> </> } />
            <Route path='searches/recent-search' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id: 'menu.listing.recentSearch'})}</PageTitle> <RecentSearch /> </> } />
            <Route path='my-listing/add' element={ <> <PageTitle breadcrumbs={ads}>{'Add Listing'}</PageTitle> <ListingActionsIntegration /> </> } />
            <Route path='edit-listing' element={ <> <PageTitle breadcrumbs={ads}>{'Edit Listing'}</PageTitle> <ListingActionsIntegration /> </> } />
            <Route path='my-listings/complete-listing' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id:"drafts.completeDraft"})}</PageTitle> <CompleteListing /> </> } />
            <Route path='activate-listing' element={ <> <PageTitle breadcrumbs={ads}>{'Activate Listing'}</PageTitle> <ActivateListing /> </> } />
            <Route path='republish-listing' element={ <> <PageTitle breadcrumbs={ads}>{'Republish Listing'}</PageTitle> <CloneListing /> </> } />
            <Route path='my-listings/job-applicants' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id : "applicants"})}</PageTitle> <Applicants /> </> } />
            <Route path='chats' element={ <> <PageTitle breadcrumbs={ads}>{Intl.formatMessage({id : "chats"})}</PageTitle> <ChatRedirect /> </> } />
        </Routes>
    )
}

export default ListingRoutes
