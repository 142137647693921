import { useContext, useEffect, useState } from 'react'
import { logOrderStartClick, logUpgradeAccountClick } from '../../../logging/helpers/commonLoggingFunctions';
import { AuthContext } from '../../i18n/AuthProvider';
import { EnumUxTypes } from '../../../logging/types/LoggingTypes';
import getMembershipFlowApi from '../../../api/getMembershipFlow.api';
import { FlowEnum } from '../../interfaces/BuyNowServiceInterface';
import { BuyNowModal } from "../accounts/wallet/BuyNowModal";
import { BundleModal } from './BundleModal';
import { extractQueryParams, setBackURLForAddPost } from '../../helpers/commonFunctions';
import { SessionDimensions } from '../../../logging/dimensions/SessionDimensions';

type Props = {
    show: boolean
    isRedirectd?: boolean
    handleClose: () => void
    type: string[]
    id?: number
    cta?: string
    pos: string
    backUrl?: string
    sku?: string
    widgetName?: string
    uxType?: EnumUxTypes
    isProBuyer?: boolean
    actionSource?: string
    triggerLogging?: boolean
    viewType?: string
}

const MemberShipModal = ({ show, handleClose, type, id, pos, backUrl, sku, cta, widgetName, isRedirectd, uxType, isProBuyer, actionSource, triggerLogging = true, viewType }: Props) => {
    const [showBuyNow, setShowBuyNow] = useState(false);
    const [showBundleModal, setShowBundleModal] = useState(false);
    const { userIdentity } = useContext(AuthContext);
    const [newType, setNewType] = useState<any>(type);

    const closeModals = () => {
        setShowBuyNow(false)
        setShowBundleModal(false);
        handleClose()
    }


    useEffect(() => {
        if (show) {
            if (type.includes('MemberShip') || type.includes('Membership') || type.includes("ProBuyer")) {
                if (!isProBuyer) {
                    getMembershipFlowApi().then(response => {
                        if (response.data.flowType == 'Bundle') {
                            if (triggerLogging) {
                                logOrderStartClick(widgetName, EnumUxTypes.BUTTON)
                            }
                            return setShowBuyNow(true)
                        } else {
                            if (!isRedirectd && triggerLogging)
                                logUpgradeAccountClick(widgetName ?? '', { id: userIdentity.id })

                            setNewType(['MemberShip'])
                            return setShowBundleModal(true)
                        }
                    })
                } else {
                    if (!isRedirectd && triggerLogging)
                        logUpgradeAccountClick(widgetName ?? '', { id: userIdentity.id })
                    return setShowBundleModal(true)
                }
            } else {
                if (triggerLogging) {
                    logOrderStartClick(widgetName, uxType ?? EnumUxTypes.BUTTON, pos)
                }
                setBackURLForAddPost();
                setTimeout(() => {
                    const url = `${process.env.REACT_APP_ADD_POST}?flow=promoteFlow&post_id=${id}&pos=${pos}${cta ? '&cta=' + cta : ''}${viewType ? '&viewType=' + viewType : ''}&return=${window.location.href}&${extractQueryParams()}${actionSource ? '&actionSource=' + actionSource : ''}`;
                    window.location.href = url;
                }, 250)
            }
        }
    }, [show])
    return <>
        <BundleModal show={showBundleModal} handleClose={closeModals} pos={pos} sku={sku} type={newType} id={id} backUrl={backUrl} />
        <BuyNowModal show={showBuyNow} handleClose={closeModals} flow={FlowEnum.ADD_LISTING} redirectTo={process.env.REACT_APP_PUBLIC_URL} />
    </>;
}


export { MemberShipModal };
