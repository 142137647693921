import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useIntl } from 'react-intl';
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import getCarReportsApi from "../../../api/servicrs/get.carReports.api";
import getEnabledServicesApi from "../../../api/servicrs/get.enabledServices.api";
import { toAbsoluteUrl } from "../../../app/helpers";
import { SearchComponent } from "../../../app/layout/components/opensooq/SearchComponent";
import { TableLoader } from "../../../app/layout/components/opensooq/TableLoader";
import { showError } from "../../helper/SystemAlert";
import { generateOpenSooqRedirectUrl, getCookies, getReportImage, isDeviceMobile, redirectionInterceptor } from "../../helpers/commonFunctions";
import { useLang } from "../../i18n/OpenSooqi18n";
import { CarReportInterface, CarReportSerivceInterface } from "../../interfaces/CarReportInterface";
import { Loader } from "../../layout/components/opensooq/Loader";
import { handleError } from "../common/HandleError";


export function CarReports() {
    const Intl = useIntl();
    const [searchData,setSearchData] = useState<CarReportInterface[]>([])
    const [showCarReportsLoader,setShowCarReportsLoader] = useState<boolean>(true)
    const [url, setUrl] = useState<string>('')
    const [lastPage, setLastPage] = useState<number | undefined>(undefined)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [searchString, setSearchString] = useState("")
    const [reportData,setReportData] = useState<CarReportInterface[]>([])
    const [enabledServices, setEnabledServices] = useState<CarReportSerivceInterface[]>([]);
    const [providers, setProviders] = useState('');
    const navigate = useNavigate();
    const lang = useLang()
    const serviceUrl = (lang === 'ar') ? 'تقارير-المركبات' : 'car-reports'
    const baseUrl =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE,getCookies().ecountry,lang)
    const openSooqUrl =  baseUrl + '/' + serviceUrl;
    useEffect(() => {
        getEnabledServicesApi().then((response)=>{
            setEnabledServices(response.data);
        }).catch((err)=>{
            handleError(err, lang,Intl)
        })
    },[])

    useEffect(()=>{
        if(enabledServices.length > 0)
        {
            let types = '';
            enabledServices.forEach((service, index) => {
                types += index+1 < enabledServices.length ? `${service.key},` : `${service.key}` ;
            });
            setProviders(types);
        }
    }, [enabledServices]);
    

    useEffect(()=>{
        if(providers.length > 0)
            fetchCarReports();
    }, [providers])

    const setSearch = (e: any) => {
        setSearchString(e.target.value);
    };

    const fetchCarReports = useCallback(()=>{     
        if( lastPage !== undefined && currentPage >= lastPage){
            return;
        }
        try{ 
            getCarReportsApi(url,providers).then (response => {
                if(response && response.data && response.data.items && response.data._meta && response.data._links)
                {   
                    setSearchData((prev) => [...prev, ...response.data.items])
                    setReportData((prev) => [...prev, ...response.data.items])
                    setShowCarReportsLoader(false)
                    setLastPage(response.data._meta.pageCount ?? 1)
                    setCurrentPage(response.data._meta.currentPage ?? 1)
                    setUrl( response.data._links.next ? response.data._links.next.href : '')
                }else 
                    throw new Error('Empty or not found response');
            })
        }catch(err){
            handleError(err, lang,Intl)
        }
    }, [enabledServices, providers, lastPage, currentPage]);


    const viewReportHandler = (report : CarReportInterface) => {
        if(report.report){
            if(report.is_url){
                window.open(report.report,'_blank')
            }else{
                navigate(`carReportDetails/${report.id}/${report.type}`);
            }
        }else{
            showError(Intl,lang)
        }
    }

    const viewReportMobileHandler = (report : CarReportInterface) => {
        if(isDeviceMobile()){
            if(report.report){
                if(report.is_url){
                    window.open(report.report,'_blank')
                }else{
                    navigate(`carReportDetails/${report.id}/${report.type}`);
                }
            }else{
                showError(Intl,lang)
            }
        }
    }
      
    const CarReportsList:React.FC<{searchData:CarReportInterface[]}> = ({searchData}) => {
        return <InfiniteScroll
            hasMore = {currentPage < (lastPage ? lastPage : currentPage)}
            dataLength={searchData.length}
            loader={
                <Loader />
            }
            pullDownToRefresh={false}
            pullDownToRefreshThreshold={100}
            pullDownToRefreshContent={null}
            releaseToRefreshContent={null}
            next={fetchCarReports}
        >   
            {searchData.map((report)=>{
                return <div className="mx-5 my-10" key={report.id} onClick={() => {viewReportMobileHandler(report)}} data-id={`car-reports-report-${report.id}`}>
                    <h6 className="fw-semibold text-muted">{report.record_insert_date}</h6>
                    <div className="d-flex align-items-center w-100 shadow-sm p-5">
                        {!report && <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <Skeleton className="symbol-label fs-2 fw-bold text-success " />
                        </div>}
                        {report && <div className="symbol symbol-75px me-5">
                            <img src={getReportImage(report.icon)} alt=""/>
                        </div>}
                        <div className="d-flex justify-content-center flex-column m-5 gap-2">
                            <h2 className="fw-bolder">{report && report.shortDescription ? report.shortDescription : '-'}</h2>
                            <span className="fw-semibold text-muted d-block fs-6">{report && report.type ? report.type.toUpperCase() : <Skeleton className="w-150px"/>}</span>
                            <span className="fw-semibold text-muted d-block fs-6">{report && report.vin_number ? `${Intl.formatMessage({id: 'products.vin'})}: ${report.vin_number}` : <Skeleton className="w-150px"/>}</span>
                            <span className="fw-semibold text-muted d-block fs-6">{report && report.expire_at ? `${Intl.formatMessage({id: 'products.expirationDate'})}: ${report.expire_at}` : <Skeleton className="w-150px"/>}</span>
                        </div>
                        {report && !isDeviceMobile() && <div className="d-flex justify-content-center flex-column ms-auto">
                            <button className="btn btn-lg btn-primary ltrDir" onClick={() => {viewReportHandler(report)}}>{Intl.formatMessage({id: 'products.view'})}</button>
                        </div>}
                    </div>
                </div>
            })}
            </InfiniteScroll>
    };

    useEffect(() => {
        const loweredSearchString = searchString.toLowerCase();
        let newList = searchData.filter(report=> (report.vin_number.includes(loweredSearchString) || report.shortDescription.toLowerCase().includes(loweredSearchString) || report.type.toLowerCase().includes(loweredSearchString)))
        if(loweredSearchString === '' ){
             newList = reportData;
        }
        setSearchData(newList);
    },[searchString]);
    
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className='row w-100 align-items-center justify-content-between'>
                        <div className='col-lg-4 col-md-6 col-6'>
                            <SearchComponent  isMobile={isDeviceMobile()}  action={setSearch}  placeholder={`${Intl.formatMessage({id: 'products.carReports.search'})}`} dataId="car-reports-search-box"/>
                        </div>
                        <div className='col-xl-3 col-md-3 col-6'>
                            <div className="d-flex justify-content-end " data-kt-filemanager-table-toolbar="base">
                                <a onClick={redirectionInterceptor} href={openSooqUrl} target="_blank" className=" btn btn-primary" data-id="car-reports-request-reportBtn">
                                    {`${Intl.formatMessage({id: 'products.requestCarReport'})} `}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {showCarReportsLoader && <TableLoader/>}
                    {!showCarReportsLoader && searchData.length > 0 && <h2 className="p-5">{Intl.formatMessage({id: 'products.total'})}: {searchData.length}</h2>}
                    {!showCarReportsLoader && searchData.length == 0 && <div className="text-center p-10">
                        <h1>{Intl.formatMessage({id : "products.noReports"})}</h1>
                        <img  src={toAbsoluteUrl('/media/custom/noCarFax.svg')} alt="no-carFax" />
                    </div>}
                    {!showCarReportsLoader && searchData.length > 0 && <CarReportsList searchData={searchData}/>}
                </div>
            </div>
        </>
    )
}