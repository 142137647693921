import clsx from 'clsx'
import { ChatCounterProvider } from 'opensooq-chat'
import { NotificationCounterProvider } from 'opensooq-notification'
import { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { logAddListingClick } from '../../../../logging/helpers/commonLoggingFunctions'
import { EnumUxTypes } from '../../../../logging/types/LoggingTypes'
import { defaultOptions, extractQueryParams, getCookies, getProfileImage, preloadImage, setCookie, showChatInCountry } from '../../../helpers/commonFunctions'
import { AuthContext } from '../../../i18n/AuthProvider'
import { useLang } from '../../../i18n/OpenSooqi18n'
import { HeaderUserMenu } from '../../../partials'
import { IframeModal } from '../modals/IframeModal'
import { ImageType } from '../../../interfaces/Image'

// const itemClass = 'ms-2 me-2';
const userAvatarClass = 'symbol-35px symbol-md-40px';

const Navbar = () => {
  const defaultProfileImage = getProfileImage();
  const Intl = useIntl();
  const lang = useLang();
  const {userIdentity} = useContext(AuthContext);
  const [show, invokeModal] = useState(false)
  const [image, setImage] = useState<ImageType>({imageSrc: defaultProfileImage, isDefault: true});
  function closePopup(){
    invokeModal(false)
  }

  const redirectToAddPost = async () => {
    //logging
    logAddListingClick(EnumUxTypes.BUTTON_TOP_BAR).finally(() => {
      setCookie("addPostRef",encodeURIComponent(window.location.href), defaultOptions);
      const link = `${process.env.REACT_APP_ADD_POST_REDIRECT_URL}?v=2&source=${getCookies().source}&lang=${lang}&flow=add&return=${window.location.href}&${extractQueryParams()}`;
      setTimeout(()=>{
        window.location.href= link;
      }, 1200)
    })
  }

  useEffect(()=>{
    if(!userIdentity || (userIdentity && userIdentity.profile_picture && userIdentity.profile_picture.includes('no_profile')))
      return;
    preloadImage(userIdentity.profile_picture, (src:string)=>{setImage({imageSrc: src, isDefault:false})});
    }, [userIdentity])

  const IconFontSizes = {
      s: "7px",
      m: "9px",
      l: "11px"
  }

  return (
    <div className='app-navbar flex-shrink-0'>
      {!isMobile && <div className={clsx('app-navbar-item')}>
        <button onClick={() => {redirectToAddPost()}} className="btn opensooqButton" data-click="navbar-addPost"><svg width="22" height="22" viewBox="0 0 24 24" className="inline vMiddle me-2" xmlns="http://www.w3.org/2000/svg" data-name="IconAddPost"><path fillRule="evenodd" clipRule="evenodd" d="M8.14179 2.65377C8.5208 2.23736 9.05779 2 9.62087 2H14.4844C15.0475 2 15.5845 2.23735 15.9635 2.65377L17.3911 4.22222H20.8947C22.1105 4.22222 23.1053 5.22222 23.1053 6.44444V19.7778C23.1053 21 22.1105 22 20.8947 22H3.21053C1.99474 22 1 21 1 19.7778V6.44444C1 5.22222 1.99474 4.22222 3.21053 4.22222H6.71421L8.14179 2.65377ZM6.52632 13.1112C6.52632 16.1778 9.00211 18.6667 12.0526 18.6667C15.1032 18.6667 17.5789 16.1778 17.5789 13.1112C17.5789 10.0445 15.1032 7.55561 12.0526 7.55561C9.00211 7.55561 6.52632 10.0445 6.52632 13.1112Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M15.3684 13.5874H12.5263V16.4445H11.5789V13.5874H8.73682V12.635H11.5789V9.77783H12.5263V12.635H15.3684V13.5874Z" fill="#fff" stroke="#fff" strokeWidth="0.1"></path></svg>{Intl.formatMessage({id: 'addListingButton'})}</button>
        <IframeModal show={show} handleClose={() => closePopup()} />
      </div>}
      {!isMobile && <div className={clsx('app-navbar-item')}>
        <Link to='/listings/demand/favorite-listings'  onClick={()=>{}}  aria-label={'favorite'}>           
        <svg width="23" height="23" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.9923 23.5936L11.008 21.8117C8.8838 19.9009 7.12807 18.2505 5.74078 16.8605C4.35328 15.4707 3.25203 14.2227 2.43703 13.1164C1.62203 12.0102 1.05182 10.9912 0.726406 10.0595C0.40099 9.12807 0.238281 8.17068 0.238281 7.18734C0.238281 5.15422 0.918177 3.45651 2.27797 2.09422C3.63776 0.731928 5.33422 0.0507812 7.36734 0.0507812C8.44568 0.0507812 9.47193 0.269947 10.4461 0.708281C11.4203 1.14682 12.269 1.77026 12.9923 2.57859C13.7157 1.77026 14.5644 1.14682 15.5386 0.708281C16.5128 0.269947 17.539 0.0507812 18.6173 0.0507812C20.6505 0.0507812 22.3494 0.731928 23.7142 2.09422C25.079 3.45651 25.7614 5.15422 25.7614 7.18734C25.7614 8.16568 25.6 9.12057 25.277 10.052C24.9541 10.9837 24.3839 12.0014 23.5664 13.1052C22.7489 14.2089 21.6452 15.4569 20.2552 16.8492C18.8654 18.2417 17.1059 19.8959 14.9767 21.8117L12.9923 23.5936Z" fill="black"/>
</svg>
        </Link>
      </div> } 
      
      <div className={clsx('app-navbar-item')}>
        <Link to='/'  aria-label={'MyOpenSooq'}>           
          <svg width="23" height="23" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="5.5" y="2.5" width="14" height="19" rx="1.5" stroke="black"></rect> <rect x="7" y="9" width="11" height="11" rx="1" fill="black"></rect> <rect x="7" y="4" width="11" height="1" rx="0.5" fill="black"></rect> <rect x="7" y="6" width="7" height="1" rx="0.5" fill="black"></rect> </svg>
        </Link>
      </div>
      <div className={clsx('app-navbar-item')}>
        <Link to='/notifications'  onClick={()=>{}}  aria-label={'notifications'}>           
          <NotificationCounterProvider render={(count) => {

            const iconFontSize = IconFontSizes[(
                count <= 9 ? "l" :
                    count <= 99 ? "m" :
                        "s"
            )]
            const CounterComponent = () => {
                if (!count)
                    return (<></>)

                return (
                    <span
                        className={`notification-navigation_counter`}
                        style={{fontSize: iconFontSize}}>{count > 99 ? "+99" : count}</span>
                )

            }

            return (
                <div
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-placement={'bottom-start'}
                >
                    <span className={'notification-navigation_container'}>
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z"
                                fill="black"/>
                        </svg>
                        <CounterComponent/>
                    </span>
                </div>
            )
            }}/>
        </Link>
      </div>
        {showChatInCountry() ? <div className={clsx('app-navbar-item')} onClick={()=> {}
        }>
        <Link to='/chats'  aria-label={'chats'}>    
        <ChatCounterProvider render={(count) => {
          const iconFontSize = IconFontSizes[(
              count <= 9 ? "l" :
                  count <= 99 ? "m" :
                      "s"
          )]

          const CounterComponent = () => {

              if (!count)
                  return (<></>)

              return (
                  <span
                      className={`chat-navigation_counter`}
                      style={{fontSize: iconFontSize}}>{count > 99 ? "+99" : count}</span>
              )

          }

          return (
              <div
                  data-kt-menu-trigger="{default: 'click'}"
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement={'bottom-start'}
              >
                  <span className={'chat-navigation_container fw-bolder'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19 4H17V13H4V15C4 15.55 4.45 16 5 16H16L20 20V5C20 4.45 19.55 4 19 4ZM15 10V1C15 0.45 14.55 0 14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10Z"
                            fill="black"/>
                    </svg>
                    <CounterComponent/>
                  </span>
              </div>
          )
          }}
          />
        </Link>
      </div> : <></>}
      <div className={clsx('app-navbar-item dropdown dropdown-menu-end')}>
        <div className={clsx('cursor-pointer  symbol ', userAvatarClass)}>
          <Dropdown>
            <Dropdown.Toggle variant="none" id="dropdown-basic" className='p-0'>
              <img width="40px" height="40px" src={image.imageSrc} alt='profileImage' className={image.isDefault && 'bg-noImage'}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <HeaderUserMenu />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export { Navbar }

