import { useChatFunctions } from "opensooq-chat";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css';
import getAdStatsApi from "../../../api/getAdStats.api";
import getListingApi from "../../../api/listing/get.listing.api";
import { CustomPopup } from "../../../app/layout/components/modals/CustomPopup";
import { IframeModal } from "../../../app/layout/components/modals/IframeModal";
import { logViewListingSuccess } from "../../../logging/helpers/commonLoggingFunctions";
import { ShareLoggingScreens } from "../../helper/ShareLogging";
import DynamicObject from "../../../models/dynamic-object";
import { EDIT, LISTING_STATISTICS, PROMOTE } from "../../helper/Ctas";
import { generateOpenSooqRedirectUrl, getCookies, isDeviceMobile } from "../../helpers/commonFunctions";
import { useLang } from "../../i18n/OpenSooqi18n";
import { ListingInfo } from "../common/ListingInfo";
import { ListingStatistics } from "../common/ListingStatistics";
import { ReactTooltipContainer } from "../common/ReactTooltipContainer";
import { ShareListings } from "../common/ShareListing";
import { ListingFeatures } from "./ListingFeatures";
import { ListingQuality } from "./ListingQuality/ListingQuality";
import { ListingQualityLoader } from "./ListingQualityLoader";
import { ManageListing } from "./ManageListing";

const MyListingView = () => {
    const {getUnreadPostRooms} = useChatFunctions()
    const cookies = getCookies();
    let navigate = useNavigate();
    const lang = useLang();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const adId = parseInt(params.get('id') ?? '');
    const action = params.get('action') ?? '';
    const [listing, setListing] = useState<DynamicObject>();
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openCustomPopup, setOpenCustomerPopup] = useState(false)
    const [viewsCountTotal, setViewsCountTotal] = useState<number>(0)
    const [callCountTotal, setCallCountTotal] = useState<number>(0)
    const [roomCountTotal, setCvCountTotal] = useState<number>(0)
    const [favoriteCountTotal, setFavoriteCountTotal] = useState<number>(0)
    const [statsLoader, setStatsLoader] = useState(true)
    const [listingUrl, setListingUrl] = useState('')
    const [count, setCount] = useState(0)
    useEffect(() => {
        getListing();
    }, []);

    useEffect(()=>{
        if(listing)
        {   
            logViewListingSuccess({
                id: listing.listing_id, 
                service: listing.service_name && listing.service_name.length > 0 ? listing.service_name[listing.service_name.length-1]?.icon : '', 
                category: { label: listing.category_reporting_name },
                city:{name: listing.city_label_en}, 
                country: listing.country_abbreviation, 
                neighborhoodName: listing.neighborhood_name_en ?? '',
                subCategory: listing.subcategory_reporting_name
            })
        }
    }, [listing])


    const getListing = () => {
        getListingApi(adId).then((response) => {
            setListing(response.data);
            if(action == EDIT && response.data.ctas.find((cta: DynamicObject) => {return cta.cta == EDIT}) && response.data.ctas.find((cta: DynamicObject) => {return cta.cta == EDIT}).cta){
                setOpenEditModal(true)
            }
            const shareLink =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',cookies.ecountry ?? 'jo',lang) + '/search/' + response.data.listing_id;
            setListingUrl(shareLink)
        }).catch(error => {
            if(error.response && error.response.status){
                navigate('/error/404')
            }
        })
        
        getAdStatsApi(adId).then(response => {
            setFavoriteCountTotal(response.data.bookmarks.total)
            setViewsCountTotal(response.data.views.total);
            setCallCountTotal(response.data.calls.total);
            setCvCountTotal(response.data.rooms.total);
            setStatsLoader(false);
        }).catch(error => {
            setStatsLoader(false);
        });
    }

    function navigateTo(url: string) {
        navigate(url);
    }

    const setOpenModal = () => {
        setShowEditListingModal(true);
    }

    const refresh = () => {
        getListing();
    }

    useEffect(() => {
        getUnreadPostRooms(adId)
            .then((value: number) => {
                if (count !== value)
                    setCount(value)
            })
    }, [getUnreadPostRooms])

    return (
        <>
            <div className="row mt-3">
                <div className="col-lg-12 ps-4 pe-4 pt-2">
                    <ListingInfo applicantsView={false} count={count} refresh={refresh} listing={listing}/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12  pt-2 pb-6">
                    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                        <div className="d-flex flex-l-row flex-column gap-7  w-lg-350px">
                            <ManageListing count={count} listing={listing} refresh={refresh}/>
                            {listing && !isDeviceMobile() && listing.ctas.find((cta: DynamicObject) => {
                                return cta.cta == EDIT
                            }) && <ShareListings dataComeFrom={ShareLoggingScreens.MY_Listing_View_Social_Widget} comeFrom={'myListingView'} listingUrl={listingUrl} listing={listing}/>}
                        </div>
                        <div className="d-flex flex-column w-100 gap-7 gap-lg-10">
                            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                                {!listing && <ListingQualityLoader/>}
                                {listing && <ListingQuality action={setOpenModal} listing={listing}/>}
                            </div>
                            <ListingFeatures listing={listing}/>
                            <ListingStatistics comeFrom="myListingView" showStatsButton={listing && listing.ctas.find((cta: DynamicObject) => {
                                return cta.cta == LISTING_STATISTICS })} showPromote={listing && listing.ctas.find((cta: DynamicObject) => {
                                return cta.cta == PROMOTE })} stats={{
                                views: viewsCountTotal,
                                favorite: favoriteCountTotal,
                                calls: callCountTotal,
                                rooms: roomCountTotal
                            }} loading={statsLoader}/>
                            {listing && isDeviceMobile() && listing.ctas.find((cta: DynamicObject) => {
                                return cta.cta == EDIT
                            }) && <ShareListings dataComeFrom={ShareLoggingScreens.MY_Listing_View_Social_Widget} comeFrom={'myListingView'} listingUrl={listingUrl} listing={listing}/>}
                        </div>
                    </div>
                </div>
            </div>
            <ReactTooltipContainer/>
            <CustomPopup show={openCustomPopup} handleClose={() => {
                setOpenCustomerPopup(false)
            }}/>
            <IframeModal show={showEditListingModal} id={listing?.listing_id} type="edit" handleClose={() => {
                setShowEditListingModal(false)
            }}/>
            <IframeModal show={openEditModal} id={listing?.listing_id} type={EDIT} handleClose={() => {setOpenEditModal(false)}} />
        </>
    )
}

export { MyListingView };
