import { useFormik } from 'formik'
import "react-datetime/css/react-datetime.css"
import { useIntl } from 'react-intl'
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import 'react-phone-number-input/style.css'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import "react-datetime/css/react-datetime.css";
import Auth from "opensooq-sso";
import sendVerificationEmail from '../../../api/profile/sendVerificationEmail'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import validateUsernameApi from '../../../api/profile/validateUsername.api'
import Skeleton from 'react-loading-skeleton'
import getCountriesApi from '../../../api/countries/getCountries.api'
import editProfileApi from '../../../api/profile/editProfile.api'
import uploadProfileImageApi from '../../../api/profile/uploadProfileImage.api'
import uploadIDApi from '../../../api/uploadID.api'
import { useLang } from '../../../app/i18n/OpenSooqi18n'
import { UploadMedia } from '../../../app/layout/components/modals/UploadMedia'
import { UploadProfileImage } from '../../../app/layout/components/modals/UploadProfileImage'
import { Button } from '../../../app/layout/components/opensooq/Button'
import DynamicObject from '../../../models/dynamic-object'
import { showError, showSuccess } from '../../helper/SystemAlert'
import { MEMBER_INFO_COOKIE } from '../../helpers/CookiesVariables'
import {
  defaultOptions,
  deleteCookie,
  generateToken,
  isEmailVerified,
  isValidEmail,
  setCookie
} from '../../helpers/commonFunctions'
import { CountriesInterface, ErrorMessages, IUpdatePassword, updatePassword } from '../../interfaces/MyProfileInterface'
import { ProfileInterface } from '../../interfaces/ProfileInterface'
import { ProfileDetailsLoader } from './profile/ProfileDetailsLoader'
import "react-datetime/css/react-datetime.css"
import styles from "./profile/CompleteProfileModal/completeProfile.module.css";
import {AuthContext} from "../../i18n/AuthProvider";
import {toAbsoluteUrl} from "../../helpers";

type MyProfileProps = {
  profileData: ProfileInterface
  fetchProfileData: () => void
}
const MyProfile = ({fetchProfileData, profileData}: MyProfileProps) => {
  const defaultProfileImage = "https://opensooqui-new.s3.amazonaws.com/api/v/icons/misc/profile_avatar.png";
  const [currentMemberPhoneCountry, setCurrentMemberPhoneCountry] = useState<CountriesInterface>()
  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({fullName: '', email: '', username: ''})
  const [countriesList, setCountriesList] = useState<CountriesInterface[]>([])
  const [profileImageModal, setProfileImageModal] = useState(false)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
  const [profile, setProfile] = useState<ProfileInterface>()
  const [profileCopy, setProfileCopy] = useState<ProfileInterface>()
  const [profileLoading, setProfileLoading] = useState(true)
  const cdn = process.env.REACT_APP_ARBOK_UPLOAD
  const [profileImageLoader, setProfileImageLoader] = useState<boolean>(false);
  const [commercilFileLoader, setCommercialFileLoader] = useState<boolean>(false);
  const [show, setShow] = useState(false)
  const [files, setFiles] = useState([])
  const [phone, setPhone] = useState('')
  const Intl = useIntl()
  const lang = useLang()
  const [fetching, setFetching] = useState(false)
  const [userNameValidate, setUserNameValidate] = useState<string>('');
  const [isCheckingUserName, setIsCheckingUserName] = useState<boolean>(false);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [storeInCookiesState, setStoreInCookiesState] = useState<boolean>(false);
  const openSooqUrl = process.env.REACT_APP_OPENSOOQ_URL_BASE.replace('/en/', '/m/');
  const {userIdentity} = useContext(AuthContext);
  const isAccountTypeIncomplete = !userIdentity?.complete_profile?.account_type_filled;
  const [inCompleteAccountType , setCompleteAccountType] = useState(!profile?.complete_profile?.account_type_filled);
  const [activeButton, setActiveButton] = useState(null);
  const handleError = (error: any) => {
    if (error && error.data) {
      showError(Intl, lang, error.data);
    } else {
      showError(Intl, lang)
    }
  }

  useEffect(() => {
    generateToken().then(() => {
    });
  }, [])

  function fetchProfile(storeInCookies: boolean = false) {
    if (storeInCookies) setStoreInCookiesState(true);
    else setStoreInCookiesState(false);
    fetchProfileData()
  }

  useEffect(() => {
    if (!profileData) return
    const results = profileData
    //setShowContact(results.hide_contact_info);
    setPhone(results.phone_number)
    setCurrentEmail(results.M_email)
    if (results.birthday) {
      let newBirthDay = results.birthday.split("-").reverse().join("-");
      results.birthday = newBirthDay
    }
    setProfile(results)
    setProfileCopy(results)
    setUserNameValidate(results.M_user_name)
    getCountriesList(results)
    if (storeInCookiesState) {
      deleteCookie(MEMBER_INFO_COOKIE);
      setCookie("userInfo", encodeURIComponent(JSON.stringify(results)), defaultOptions);
    }
  }, [profileData])

  function getCountriesList(profile: ProfileInterface) {
    getCountriesApi().then((response) => {
      const countries = response.data
      setCountriesList(countries)
      const memberCountryFlag = response.data.filter((country: CountriesInterface) => {
        return country.phone_code.toString() == profile.phone_country_code
      })
      setCurrentMemberPhoneCountry(memberCountryFlag[0])
      setProfileLoading(false)
    })
  }


  const passwordFormValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .min(3, Intl.formatMessage({id: "myProfile.profileDetails.text37"}))
        .max(50, Intl.formatMessage({id: "myProfile.profileDetails.text36"}))
        .required(Intl.formatMessage({id: "myProfile.profileDetails.text35"})),
    newPassword: Yup.string()
        .required(Intl.formatMessage({id: "myProfile.profileDetails.text35"})),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], Intl.formatMessage({id: "myProfile.profileDetails.text34"}))
        .required(Intl.formatMessage({id: "myProfile.profileDetails.text35"}))
  })

  const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
      setTimeout((values: any) => {
        setPasswordUpdateData(values)
        setPasswordForm(false)
      }, 1000)
    },
  })

  const updateFullName = (event: any) => {
    if (event.target.value.length < 1) {
      setErrorMessages((old: any) => {
        return {...old, fullName: Intl.formatMessage({id: 'myProfile.profileDetails.text28'})}
      })
    } else {
      setErrorMessages((old: any) => {
        return {...old, fullName: ''}
      })
    }
    setProfile((old: any) => {
      return {
        ...old,
        full_name: event.target.value,
      }
    })
  }

  const changeGender = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProfile((old: any) => {
      return {
        ...old,
        gender: parseInt(e.target.value),
      }
    })
  }

  const updateBirthday = (e: any) => {
    return setProfile((old: any) => {
      return {
        ...old,
        birthday: e.format('DD-MM-YYYY'),
      }
    })
  }

  const updateHideContactInfo = (event: any) => {
    setProfile((old: any) => {
      return {
        ...old,
        hide_contact_info: !old.hide_contact_info,
      }
    })
  }

  const updateEmail = (event: any) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (event.target.value.length > 0 && !regex.test(event.target.value)) {
      setErrorMessages((old: any) => {
        return {...old, email: Intl.formatMessage({id: 'myProfile.profileDetails.text33'})}
      })
    } else {
      setErrorMessages((old: any) => {
        return {...old, email: ''}
      })
    }
    setProfile((old: any) => {
      return {
        ...old,
        M_email: event.target.value,
      }
    })
  }

  const saveChanges = () => {
    if (errorMessages.fullName !== '' || errorMessages.email !== '' || errorMessages.username !== '') {
      return
    }
    Swal.fire({
      icon: 'warning',
      html: `<div className="pe-10 ps-10 fw-bolder">${Intl.formatMessage({id: "editProfileConfirmationMessage"})}</div>`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: Intl.formatMessage({id: "no"}),
      confirmButtonText: Intl.formatMessage({id: "yes"}),
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-danger',
      },
    }).then((result) => {
      const data = {
        Member: {
          M_email: profile ? profile.M_email : '',
          full_name: profile ? profile.full_name : '',
          M_user_name: profile ? profile.M_user_name : '',
          hide_contact_info: profile?.hide_contact_info,
          gender: profile ? profile.gender : '',
          birthday: (profile && profile.birthday) ? profile.birthday.split("-").reverse().join("-") : '',
          account_type: profile ? profile.account_type : ""
        },
      }
      if (result.isConfirmed) {
        editProfileHandler(data);
      }
    })
  }

  const handleClick = (type) => {
    setProfile((prev : any) => ({
      ...prev,
      account_type: `${type}`,
    }));
    setActiveButton(type);
  };

  const editProfileHandler = async (data: DynamicObject) => {
    const newPassword = formik2.values.newPassword;
    const isProfileChanged = JSON.stringify(profile) !== JSON.stringify(profileCopy);
    const isPasswordChanged = newPassword && newPassword.length > 0;
    if (isProfileChanged) {
      editProfileApi(data).then((response) => {
        if (response && response.status) {
          setActiveButton(data?.Member?.account_type);
          setTimeout(() => {
            setCompleteAccountType(!response?.data?.complete_profile?.account_type_filled);
          } ,300);

          fetchProfile();
            if (!isPasswordChanged)
            showSuccess(Intl, lang)
        }
      }).catch((error) => {
        handleError(error.response)
      })
    }
    if (isPasswordChanged) {
      changePassword().then((response) => {
        showSuccess(Intl, lang);
      }).catch((error) => {
        showError(Intl, lang, {'message': error.newPassword});
      })
    }
    if (!isPasswordChanged && !isProfileChanged)
      showSuccess(Intl, lang);
  }

  const discardChanges = () => {
    setFetching(true)
    setProfile({...profileCopy, hide_contact_info: profileCopy?.hide_contact_info ? profileCopy?.hide_contact_info : false})
    setUserNameValidate(profileCopy?.M_user_name ? profileCopy?.M_user_name : '')
    setErrorMessages({fullName: '', email: '', username: ''})
    setTimeout(() => {
      setFetching(false)
    }, 0)
  }

  const hideCommercialFileModal = () => {
    setShow(false)
    setCommercialFileLoader(false);
    setFiles([])
  }

  const hideProfileImageModal = () => {
    setProfileImageLoader(false);
    setProfileImageModal(false)
  }

  const uploadCommercialFile = (files: any) => {
    setCommercialFileLoader(true);
    const file = files[0].file
    setFiles(files)
    const formData = new FormData()
    formData.append('MemberMedia[img_file]', file)
    uploadIDApi(formData)
        .then((response) => {
          if (response.status === 201) {
            showSuccess(Intl, lang)
            hideCommercialFileModal();
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          setCommercialFileLoader(false);
          handleError(error.response);
        })
  }

  const uploadProfileImage = (files: any) => {
    setProfileImageLoader(true);
    const file = files[0].file
    setFiles(files)
    const formData = new FormData()
    formData.append('Member[avatarFile]', file)
    uploadProfileImageApi(formData)
        .then((response) => {
          fetchProfile(true)
          hideProfileImageModal()
          showSuccess(Intl, lang)
        })
        .catch((error) => {
          setProfileImageLoader(false);
          handleError(error.response);
        })
  }

  const setFilesData = (files: any) => {
    setFiles(files)
  }

  const changePhone = async () => {
    await Auth.getInstance().changeMobilePhone(() => {
      window.location.reload();
    })
  }

  const changePassword = async () => {
    await Auth.getInstance().changePassword();
  }

  const verifyEmail = (profile: ProfileInterface) => {
    let data = {
      email: profile.M_email ? profile.M_email : profile.M_new_email
    };
    sendVerificationEmail(data).then((response) => {
      if (response.status === 200) {
        showSuccess(Intl, lang)
      } else {
        showError(Intl, lang)
      }
    }).catch((error) => {
      handleError(error.response)
    })
  }

  const validateUserName = (event: any) => {
    setIsCheckingUserName(true);
    setUserNameValidate(event.target.value);
    let data = {
      Member: {
        'M_user_name': event.target.value
      }
    }

    validateUsernameApi(data).then((response) => {
      setIsCheckingUserName(false);
      if (response.status === 200) {
        setProfile((old: any) => {
          return {
            ...old,
            M_user_name: event.target.value,
          }
        })
        setErrorMessages((old: any) => {
          return {...old, username: ''}
        })
      } else {
        setErrorMessages((old: any) => {
          return {...old, username: response.data}
        })
      }
    }).catch((error) => {
      setIsCheckingUserName(false);
      setErrorMessages((old: any) => {
        return {...old, username: error.response?.data[0]?.message}
      })
    })
  }

  useEffect(() => {
    const handleProfileUpdate = () => {
      fetchProfileData();
    };
    window.addEventListener('profileUpdated', handleProfileUpdate);
    return () => {
      window.removeEventListener('profileUpdated', handleProfileUpdate);
    };
  }, []);

  useEffect(() => {
    if ((inCompleteAccountType) !== !profile?.complete_profile?.account_type_filled) {
      setCompleteAccountType(!profile?.complete_profile?.account_type_filled);
    }
  }, [isAccountTypeIncomplete, profile?.account_type]);


  return (
      <>
        {profileLoading && <ProfileDetailsLoader/>}
        {!profileLoading && (
            <div className='card mb-5 mb-xl-10' data-select2-id='select2-data-134-v21y'>
              <div className='card-header'>
                <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-800'>
                {Intl.formatMessage({id: 'myProfile.profileDetails.text1'})}
              </span>
                </h3>
              </div>

              <div
                  id='kt_account_settings_profile_details'
                  className='collapse show'
                  data-select2-id='select2-data-kt_account_settings_profile_details'
              >
                <div className='card-body border-top p-8' data-select2-id='select2-data-362-ogaw'>
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-form-label fw-semibold fs-6'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text2'})}
                    </label>
                    <div className='col-lg-10'>
                      <div
                          className='image-input image-input-outline'
                          data-kt-image-input='true'
                          style={{
                            backgroundImage: `url('${toAbsoluteUrl('/media/svg/avatars/blank.svg')}')`,
                          }}
                      >
                        <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{backgroundImage: `url(${profile?.profile_picture_large.includes('no_profile') ? defaultProfileImage : profile?.profile_picture_large})`}}
                        >
                          <label
                              onClick={() => {
                                setProfileImageModal(true)
                              }}
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          >
                            {' '}
                            <i className='fa-solid fa-pen-to-square fs-2'></i>{' '}
                          </label>
                        </div>
                        <div className='form-text'>
                          {Intl.formatMessage({id: 'myProfile.profileDetails.text2'})}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text3'})}
                    </label>
                    <div className='col-lg-10 col-9'>
                      <div className='row'>
                        <div className='col-6 fv-row fv-plugins-icon-container'>
                          <input
                              type='text'
                              onChange={(e: any) => {
                                updateFullName(e)
                              }}
                              data-id="edit-profile-fullNameField"
                              className='form-control form-control-lg form-control-solid '
                              placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text3'})}
                              value={(profile && profile.full_name) || ''}
                          />
                          <div className='fv-plugins-message-container invalid-feedback'>
                            {errorMessages && errorMessages?.fullName !== ''
                                ? errorMessages.fullName
                                : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text4'})}
                    </label>
                    <div className='col-lg-10 col-9'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                          <input
                              data-id="edit-profile-emailField"
                              type='text'
                              onChange={(e: any) => {
                                updateEmail(e)
                              }}
                              className='form-control form-control-lg form-control-solid '
                              placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text4'})}
                              value={(profile && profile.M_email) ? profile.M_email : ''}
                          />
                          <div className='fv-plugins-message-container invalid-feedback'>
                            {errorMessages && errorMessages?.email !== '' ? errorMessages.email : ''}
                          </div>
                        </div>
                        {profile && (!isEmailVerified(profile.member_verification) || currentEmail != profile.M_email) && isValidEmail(profile.M_email) &&
                            <div className='col-lg-6 col-12 fv-row fv-plugins-icon-container'>
                              <button data-id="edit-profile-verifyEmailBtn" onClick={() => {
                                verifyEmail(profile)
                              }} className='btn btn-light  btn-active-light-primary'>
                                {Intl.formatMessage({id: 'myProfile.profileDetails.text44'})}
                              </button>
                            </div>}
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text5'})}{' '}
                      <i id='phone' className='fas fa-exclamation-circle ms-1 fs-7'></i>
                    </label>
                    <div className='col-lg-10 col-9'>
                      <div className='row'>
                        <div className='col-lg-6 col-12 fv-row fv-plugins-icon-container'>
                          <div className='input-group input-group-solid mb-5'>
                        <span className='input-group-text' id='basic-addon1'>
                          <img
                              className='w-25px '
                              src={
                                  profile &&
                                  countriesList &&
                                  currentMemberPhoneCountry &&
                                  currentMemberPhoneCountry.icon
                              }
                              alt=''
                          />
                        </span>
                            <input
                                data-id="edit-profile-phoneNumberField"
                                disabled
                                type='text'
                                className='form-control'
                                placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text5'})}
                                defaultValue={phone}
                            />
                          </div>
                        </div>
                        {currentMemberPhoneCountry && (
                            <div className='col-lg-6 col-12 fv-row fv-plugins-icon-container'>
                              <button
                                  data-id="edit-profile-changePhoneNumberBtn"
                                  onClick={() => {
                                    changePhone()
                                    // setOpenEditPhone(true)
                                  }}
                                  className='btn btn-light  btn-active-light-primary'
                              >
                                {Intl.formatMessage({id: 'myProfile.profileDetails.text10'})}
                              </button>
                            </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text45'})}
                    </label>
                    <div className='col-lg-10 col-9'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                          <input
                              data-id="edit-profile-profileLinkField"
                              type='text'
                              onChange={(e: any) => {
                                validateUserName(e)
                              }}
                              className='form-control form-control-lg form-control-solid '
                              placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text45'})}
                              value={(profile && userNameValidate) || ''}
                          />
                          {!isCheckingUserName && errorMessages?.username && <div
                              className='fv-plugins-message-container invalid-feedback'>{errorMessages.username}</div>}
                          {!isCheckingUserName && !errorMessages?.username && <div
                              className='fv-plugins-message-container mx-2 invalid-feedback text-black'>{`${openSooqUrl}${userNameValidate}`}</div>}
                          {isCheckingUserName && <Skeleton className='w-100' style={{height: "19px"}}/>}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text39'})}
                    </label>
                    <div className='col-lg-10 col-9'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                          <select data-id="edit-profile-genderSelect" className="form-select form-select-solid"
                                  aria-label="Select example" onChange={changeGender}
                                  value={profile && profile.gender ? profile.gender : ""}>
                            <option value=""
                                    disabled>{Intl.formatMessage({id: 'myProfile.profileDetails.text40'})}</option>
                            <option value="1"
                                    data-id="edit-profile-genderSelect-male">{Intl.formatMessage({id: 'myProfile.profileDetails.text41'})}</option>
                            <option value="2"
                                    data-id="edit-profile-genderSelect-female">{Intl.formatMessage({id: 'myProfile.profileDetails.text42'})}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text43'})}
                    </label>
                    <div className='col-lg-10 col-9'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row fv-plugins-icon-container'
                             data-id="edit-profile-dateOfBirthField">
                          {!fetching &&
                              <Datetime onChange={updateBirthday} value={profile.birthday ?? ''} initialValue=""
                                        closeOnSelect={true} dateFormat={"DD-MM-YYYY"} timeFormat={false}
                                        inputProps={{autoComplete: "disabled", name: "input", placeholder: Intl.formatMessage({id: "birthday.placeholder"}), disabled: false, className: "form-control form-control-solid"}}/>}
                          {fetching && <Datetime value={profileCopy.birthday ?? ''} initialValue="" closeOnSelect={true}
                                                 dateFormat={"DD-MM-YYYY"} timeFormat={false}
                                                 inputProps={{autoComplete: "disabled", name: "input", placeholder: Intl.formatMessage({id: "birthday.placeholder"}), disabled: true, className: "form-control form-control-solid"}}/>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6 d-none'>
                    <label className='col-lg-2 col-3 col-form-label fw-semibold fs-6'>
                    </label>
                    <div className='col-lg-10 col-9'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                          <input type='text'></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <label className='col-lg-2 col-3 col-form-label fw-semibold fs-6'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text6'})}
                    </label>
                    <div className='col-lg-10 col-9'>
                      <div className='row mb-10'>
                        <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                          <div className='input-group input-group-solid mb-5'>
                            <div style={{display: "none"}}>
                              <input type="text" id="PreventChromeAutocomplete"
                                     name="PreventChromeAutocomplete" autoComplete="address-level4"/>
                            </div>
                            <input
                                type='text'
                                disabled
                                className='form-control'
                                placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text6'})}
                                defaultValue='************'
                            />
                          </div>
                        </div>
                        <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                          <button
                              data-id="edit-profile-changePasswordBtn"
                              onClick={() => {
                                changePassword()
                              }}
                              className='btn btn-light btn-active-light-primary'
                          >
                            {Intl.formatMessage({id: 'myProfile.profileDetails.text11'})}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(inCompleteAccountType || !profile?.complete_profile?.account_type_filled) ? (
                      <div className='row mb-6'>
                        <label className='col-lg-2 col-3 col-form-label fw-semibold fs-6'>
                          {Intl.formatMessage({id: 'accountType'})}
                        </label>
                        <div className='col-lg-10 col-9'>
                          <div className='row mb-10'>
                            <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                              {[1, 2].map((type) => (
                                  <div key={type} className={`${styles.fakeRadioButtonWrapper} d-flex gap-3 mb-8 border rounded-2 p-3 text-body hoverPointer ${
                                          activeButton === type ? styles.active : ''
                                      }`} onClick={() => {
                                        handleClick(type)
                                      }}>
                                    <div>
                                      <div className={`${styles.fakeRadioButton} position-relative rounded-circle ${styles.circleBorder} bg-white`}>
                                <span className={`rounded-circle d-block ${activeButton === type ? 'bg-primary' : ''}`}/>
                                      </div>
                                    </div>
                                    <div>
                                      <strong className={styles.fontSizeAccountType}>
                                        {Intl.formatMessage({id: `accountTypeTitle_${type}`})}
                                      </strong>
                                      <p className={`${styles.fontSizeAccountTypeProfileSubTitle} mt-2 fs-5`}>
                                        {Intl.formatMessage({id: `accountType_p${type}`})}
                                      </p>
                                    </div>
                                  </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                  ) : (
                      <div className='row mb-6'>
                        <label className='col-lg-2 col-3 col-form-label fw-semibold fs-6'>
                          {Intl.formatMessage({id: 'accountType'})}
                        </label>
                        <div className='col-lg-10 col-9'>
                          <div className='row mb-10'>
                            <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                              {[1, 2].map((type) =>
                                      profile.account_type === type && (
                                          <div key={type}
                                              className={`${styles.fakeRadioButtonWrapper} d-flex gap-3 mb-8 border rounded-2 p-3 text-body`}>
                                            <div>
                                              <strong className={styles.fontSizeAccountType}>
                                                {Intl.formatMessage({id: `accountTypeTitle_${type}`})}
                                              </strong>
                                              <p className={`${styles.fontSizeAccountTypeProfileSubTitle} mt-2 fs-5`}>
                                                {Intl.formatMessage({id: `accountType_p${type}`})}
                                              </p>
                                            </div>
                                          </div>
                                      ))}
                            </div>
                          </div>
                        </div>
                      </div>
                  )}

                  <div className='row mb-6'>
                    <div className='d-flex flex-stack'>
                      <div className='d-flex'>
                        <div className='d-flex flex-column'>
                          <a href='#' className='fs-5 text-dark text-hover-primary fw-bolder'>
                            {Intl.formatMessage({id: 'myProfile.profileDetails.text12'})}
                          </a>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {Intl.formatMessage({id: 'myProfile.profileDetails.text13'})}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <div className='form-check form-switch form-check-custom form-check-solid'>
                          <input
                              data-id="edit-profile-hideContactInfoSwitch"
                              className='form-check-input'
                              type='checkbox'
                              checked={profile && profile.hide_contact_info}
                              id='flexSwitchDefault'
                              onChange={(e: any) => {
                                updateHideContactInfo(e)
                              }}
                          />
                          <label className='form-check-label' htmlFor={'flexSwitchDefault'}>
                            {' '}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <Button
                      setData="edit-profile-discardChangesBtn"
                      text={Intl.formatMessage({id: 'myProfile.profileDetails.text26'})}
                      className={'btn-light btn-active-light-primary me-2'}
                      action={discardChanges}
                  />
                  <Button
                      setData="edit-profile-saveChangesBtn"
                      text={Intl.formatMessage({id: 'myProfile.profileDetails.text25'})}
                      className={'btn-primary'}
                      disabled={errorMessages.fullName !== '' || (errorMessages.email !== '') || formik2.errors.newPassword !== undefined || formik2.errors.passwordConfirmation !== undefined || errorMessages.username !== '' || isCheckingUserName}
                      action={saveChanges}
                  />
                </div>
              </div>
            </div>
        )}
        <UploadMedia loader={commercilFileLoader} uploadFile={uploadCommercialFile} setFilesData={setFilesData}
                     files={files} show={show} handleClose={hideCommercialFileModal}/>
        <UploadProfileImage loader={profileImageLoader} uploadFile={uploadProfileImage} show={profileImageModal}
                            handleClose={hideProfileImageModal}/>
      </>
  )
};

export { MyProfile }

