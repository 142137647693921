import { defaultOptions, jsonSafeGet, setCookie } from "../../app/helpers/commonFunctions";
import { ActionDimensions } from "../dimensions/ActionDimensions";
import { ActionName, EMPTY_ACTION_NAME, EnumActionNameLevel1, EnumActionNameLevel2, EnumActionNameLevel3 } from "../types/LoggingTypes";
import Cookies from 'js-cookie';

const updateActionHistory = async (L1:EnumActionNameLevel1, L2:EnumActionNameLevel2, L3:EnumActionNameLevel3)=>{
    let prevActionString = Cookies.get('prevAction');
    let prevAction : ActionName;
    if(!prevActionString)
        prevAction = EMPTY_ACTION_NAME;
    else
        prevAction = jsonSafeGet(prevActionString);
    ActionDimensions.getInstance().actionName = {L1,L2,L3};
    ActionDimensions.getInstance().prevActionName = prevAction;
    setCookie('prevAction', JSON.stringify({L1,L2,L3}), {...defaultOptions, expires: 10})
}

export {updateActionHistory}