import React, {useContext, useEffect, useRef, useState} from 'react'
import {Form, Modal, Row, Col} from 'react-bootstrap'
import styles from './completeProfile.module.css'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import editProfileApi from '../../../../../api/profile/editProfile.api'
import {showSuccess} from '../../../../helper/SystemAlert'
import sendVerificationEmail from '../../../../../api/profile/sendVerificationEmail'
import Datetime from 'react-datetime'
import {AuthContext} from '../../../../i18n/AuthProvider'
import {toastify} from '../../../../../tostify/snackbarAlert'
import {useOnClickOutside} from '../../../../helpers/useOnClickOutside'
import IconDownArrowHead from '../../../../icons/IconDownArrowHead'
import IconClose from '../../../../icons/IconClose'
import {getCookies} from '../../../../helpers/commonFunctions'

interface ProfileCompletionModalProps {
  show: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ProfileCompletionModalDesktop: React.FC<ProfileCompletionModalProps> = ({
  show,
  setShowModal,
}) => {
  const [formData, setFormData] = useState({
    M_email: '',
    gender: '',
    dateOfBirth: '',
    accountType: '',
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const listRef = useRef<any>()
  const intl = useIntl()
  const lang = useLang()
  const [errorMessages, setErrorMessages] = useState({email: '', birthday: ''})
  const {userIdentity} = useContext(AuthContext)
  const [activeButton, setActiveButton] = useState(null)
  const getUpdatedUserProfileCookie = getCookies().saveUserProfileData
  const [popupCount, setPopupCount] = useState(0)

  useEffect(() => {
    const popupData = JSON.parse(getCookies().popupAppearanceDays || '[]')

    if (popupData.length > 0 && userIdentity?.id) {
      const userCounts = popupData.filter((item) => item.userId === userIdentity?.id)

      if (userCounts.length > 0) {
        const totalCount = userCounts.reduce((sum, item) => sum + (item.count || 0), 0)
        setPopupCount(totalCount)
      } else {
        setPopupCount(0)
      }
    } else {
      setPopupCount(0)
    }
  }, [userIdentity])

  const toggleDropdown = (event) => {
    event.stopPropagation()
    setIsOpen((prev) => !prev)
  }

  useOnClickOutside(listRef, () => {
    setIsOpen(false)
  })

  const handleGenderSelect = (value: string) => {
    const genderValue = value === 'Male' ? '1' : '2'
    setFormData((prevData) => ({
      ...prevData,
      gender: genderValue,
    }))
    setIsOpen(false)
  }

  const verifyEmail = (event) => {
    event.preventDefault()
    const data = {email: formData.M_email}
    const errorMessage = intl.formatMessage({id: 'myProfile.profileDetails.text49'})
    const enterEmailMessage = intl.formatMessage({
      id: 'myProfile.profileDetails.pleaseEnterTheEmail',
    })
    const successMessage = intl.formatMessage({id: 'myProfile.profileDetails.text48'})
    sendVerificationEmail(data)
      .then((response) => {
        if (response.status === 200) {
          toastify({severity: 'success', title: successMessage, locale: lang})
          setErrorMessages((prevData) => ({
            ...prevData,
            email: '',
          }))
        } else {
        }
      })
      .catch((errorResponse) => {
        const errorData = errorResponse.response?.data

        const emailErrorMessage =
          Array.isArray(errorData) && errorData?.[0]?.message
            ? errorData?.[0].message
            : errorData?.message
            ? errorData?.message
            : formData?.M_email.length > 0
            ? errorMessage
            : enterEmailMessage

        setErrorMessages((prevData) => ({
          ...prevData,
          email: emailErrorMessage,
        }))
      })
  }

  const saveChanges = (event) => {
    event.preventDefault()
    const data = {
      Member: {
        M_email: formData.M_email || '',
        gender: formData.gender || '',
        birthday: formData.dateOfBirth || '',
        account_type: formData.accountType || '',
      },
    }

    const errorMessage = intl.formatMessage({id: 'myProfile.profileDetails.text49'})

    editProfileApi(data)
      .then(() => {
        setErrorMessages((prevData) => ({
          ...prevData,
          birthday: '',
        }))
        setShowModal(false)
        showSuccess(intl, lang)
      })
      .catch((error) => {
        if (error?.response?.data) {
          error.response.data.forEach((err) => {
            if (err.field === 'birthday') {
              setErrorMessages((prevData) => ({
                ...prevData,
                birthday: err.message,
              }))
            }

            if (err.field === 'M_email') {
              const emailErrorMessage =
                Array.isArray(error?.response?.data) && error?.response?.data?.[0]?.message
                  ? error?.response?.data?.[0].message
                  : error?.response?.data?.message
                  ? error?.response?.data?.message
                  : errorMessage

              setErrorMessages((prevData) => ({
                ...prevData,
                email: emailErrorMessage,
              }))
            }
          })
        }
      })
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const modalDialog = document.querySelector('.modal.show .modal-dialog') as HTMLElement
        const dialogElement = document.querySelector('.fade.modal.show') as HTMLElement
        if (modalDialog) {
          modalDialog.style.maxWidth = '550px'
        }
        if (dialogElement) {
          dialogElement.style.paddingRight = '0'
          dialogElement.style.paddingLeft = '0'
        }
      }, 0)
    }
  }, [show])

  const handleClick = (index) => {
    setActiveButton(index)
  }

  const isPopupThresholdMet = popupCount >= userIdentity?.complete_profile?.required_after
  const isFormIncomplete =
    (!userIdentity?.complete_profile?.date_of_birth_filled && formData.dateOfBirth === '') ||
    (!userIdentity?.complete_profile?.gender_filled && formData.gender === '') ||
    (!userIdentity?.complete_profile?.email_filled && formData.M_email === '') ||
    (!userIdentity?.complete_profile?.account_type_filled && formData.accountType === '')
  const shouldTriggerPopup = isPopupThresholdMet && isFormIncomplete

  const notOfAnyFilesFilled =
    formData.dateOfBirth === '' &&
    formData.gender === '' &&
    formData.M_email === '' &&
    formData.accountType === ''

  return (
    <>
      <Modal show={show} centered dialogClassName={styles.DesktopModal}>
        <Modal.Body>
          <div className='border-bottom mb-6'>
            <div className='d-flex flex-column'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className={`text-center mt-4 font fw-bold ${styles.closeIcon}`}>
                  {intl.formatMessage({id: 'myProfile.profileDetails.completeYourProfile'})}
                </div>
                {popupCount < userIdentity?.complete_profile?.required_after && (
                  <IconClose
                    fill='#000'
                    height='22'
                    width='22'
                    onClick={() => {
                      if (getUpdatedUserProfileCookie) {
                        document.cookie =
                          'saveUserProfileData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
                      }
                      setShowModal(false)
                    }}
                  />
                )}
              </div>
              <div className={`text-left mt-2 mb-4 ${styles.subTitle}`}>
                {intl.formatMessage({id: 'myProfile.profileDetails.provideYourInfo'})}
              </div>
            </div>
          </div>
          {!userIdentity?.complete_profile?.email_filled && (
            <div className='mb-10'>
              <label className={`${styles.labelSize} text-start fw-bold`}>
                {intl.formatMessage({id: 'myProfile.profileDetails.text4'})}
              </label>
              <Form.Group as={Row} controlId='formEmail'>
                <Col sm={12} className='position-relative rounded'>
                  <Form.Control
                    type='text'
                    name='M_email'
                    value={formData.M_email}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        M_email: e.target.value.trim(),
                      })
                    }
                    placeholder={intl.formatMessage({
                      id: 'myProfile.profileDetails.enterYourEmail',
                    })}
                    className='p-3 pt-4 pb-4 text-start fs-5'
                  />
                  <button
                    style={{
                      left: lang === 'ar' ? '18px' : 'auto',
                      right: lang === 'ar' ? 'auto' : '15px',
                      top: errorMessages.email ? '32%' : '48%',
                      transform: 'translateY(-50%)',
                      color: '#808080',
                    }}
                    className='position-absolute border-0 bg-white fs-5 p-2 fw-bold'
                    onClick={(event) => verifyEmail(event)}
                  >
                    {intl.formatMessage({id: 'myProfile.profileDetails.text46'})}
                  </button>
                  {errorMessages.email && (
                    <div className='fv-plugins-message-container invalid-feedback fs-6 mt-3'>
                      {errorMessages.email}
                    </div>
                  )}
                </Col>
              </Form.Group>
            </div>
          )}

          {!userIdentity?.complete_profile?.gender_filled && (
            <div className='mb-10'>
              <label className={`${styles.labelSize} text-start fw-bold`}>
                {intl.formatMessage({id: 'myProfile.profileDetails.text39'})}
              </label>
              <div
                className={`${styles.genderSelect} p-3 pt-4 pb-4 border rounded position-relative`}
                ref={listRef}
                onClick={toggleDropdown}
              >
                <div className='d-flex justify-content-between align-items-center fs-5'>
                  {formData.gender === '1'
                    ? intl.formatMessage({id: 'myProfile.profileDetails.text41'})
                    : formData.gender === '2'
                    ? intl.formatMessage({id: 'myProfile.profileDetails.text42'})
                    : intl.formatMessage({id: 'myProfile.profileDetails.selectGender'})}
                  <IconDownArrowHead fill='#000' />
                </div>
                {isOpen && (
                  <ul className='position-absolute width-100 fullShadow border bg-white'>
                    <li
                      className='pt-5 pb-5 fs-4'
                      onClick={(e) => {
                        e.stopPropagation()
                        handleGenderSelect('Male')
                      }}
                      data-id='edit-profile-genderSelect-male'
                    >
                      {intl.formatMessage({id: 'myProfile.profileDetails.text41'})}
                    </li>
                    <li
                      className='pt-5 pb-5 fs-4'
                      onClick={(e) => {
                        e.stopPropagation()
                        handleGenderSelect('Female')
                      }}
                      data-id='edit-profile-genderSelect-female'
                    >
                      {intl.formatMessage({id: 'myProfile.profileDetails.text42'})}
                    </li>
                  </ul>
                )}
              </div>
            </div>
          )}

          {!userIdentity?.complete_profile?.date_of_birth_filled && (
            <div className='mb-9'>
              <label className={`${styles.labelSize} text-start fw-bold`}>
                {intl.formatMessage({id: 'myProfile.profileDetails.text43'})}
              </label>
              <Form.Group as={Row} controlId='formBirthday' className='align-items-center'>
                <Col sm={12}>
                  <Datetime
                    value={formData.dateOfBirth ?? ''}
                    initialValue=''
                    closeOnSelect={true}
                    dateFormat={'DD-MM-YYYY'}
                    timeFormat={false}
                    inputProps={{
                      autoComplete: 'disabled',
                      name: 'dateOfBirth',
                      placeholder: intl.formatMessage({id: 'birthday.placeholder'}),
                      className: 'form-control form-control-lg p-3 pt-5 pb-5 fs-5',
                    }}
                    onChange={(date) => {
                      const formattedDate =
                        typeof date === 'object' && 'format' in date
                          ? date.format('YYYY-MM-DD')
                          : date
                      setFormData({...formData, dateOfBirth: formattedDate})
                    }}
                  />
                  {errorMessages.birthday && (
                    <div className='fv-plugins-message-container invalid-feedback mt-3 fs-6'>
                      {errorMessages.birthday}
                    </div>
                  )}
                </Col>
              </Form.Group>
            </div>
          )}

          {!userIdentity?.complete_profile?.account_type_filled && (
            <div className='mb-9'>
              <label className={`${styles.labelSize} text-start fw-bold`}>
                {intl.formatMessage({id: 'accountType'})}
              </label>

              <div
                className={`${
                  styles.fakeRadioButtonWrapper
                } d-flex gap-3 mb-8 border rounded-2 p-3 text-body hoverPointer ${
                  activeButton === 1 ? styles.active : ''
                }`}
                onClick={() => {
                  handleClick(1)
                  setFormData({...formData, accountType: '1'})
                }}
              >
                <div>
                  <div
                    className={`${styles.fakeRadioButton} position-relative rounded-circle ${styles.circleBorder} bg-white`}
                  >
                    <span
                      className={`rounded-circle d-block ${activeButton === 1 ? 'bg-primary' : ''}`}
                    />
                  </div>
                </div>
                <div>
                  <strong className={`${styles.fontSizeAccountType}`}>
                    {intl.formatMessage({id: 'accountTypeTitle_1'})}
                  </strong>
                  <p className={`${styles.fontSizeAccountTypeSubTitle} mt-2`}>
                    {intl.formatMessage({id: 'accountType_p1'})}
                  </p>
                </div>
              </div>

              <div
                className={`${
                  styles.fakeRadioButtonWrapper
                } d-flex gap-3 border rounded-2 p-3 text-body hoverPointer ${
                  activeButton === 2 ? styles.active : ''
                }`}
                onClick={() => {
                  handleClick(2)
                  setFormData({...formData, accountType: '2'})
                }}
              >
                <div>
                  <div
                    className={`${styles.fakeRadioButton} position-relative rounded-circle ${styles.circleBorder} bg-white`}
                  >
                    <span
                      className={`rounded-circle d-block ${activeButton === 2 ? 'bg-primary' : ''}`}
                    />
                  </div>
                </div>
                <div>
                  <strong className={`${styles.fontSizeAccountType}`}>
                    {intl.formatMessage({id: 'accountTypeTitle_2'})}
                  </strong>
                  <p className={`${styles.fontSizeAccountTypeSubTitle} mt-2`}>
                    {intl.formatMessage({id: 'accountType_p2'})}
                  </p>
                </div>
              </div>
            </div>
          )}

          <Form.Group as={Row} className='mb-4 mt-8'>
            <Col sm={{span: 10}}>
              <button
                className={`fw-bold rounded p-4 ms-1 ${
                  notOfAnyFilesFilled || shouldTriggerPopup
                    ? styles.btnGray
                    : `${styles.saveButton} ${styles.saveFont}`
                }`}
                style={{width: '133px'}}
                onClick={(event) => saveChanges(event)}
              >
                {intl.formatMessage({id: 'mylistings.filter.rangesave'})}
              </button>
            </Col>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProfileCompletionModalDesktop
