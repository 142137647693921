import {AxiosResponse} from "axios";
import Api from "../api";

export default (): Promise<AxiosResponse> => {
    const path = '/account/listings/my-listings/recently-viewed';
    return Api.getInstance(false, false, process.env.REACT_APP_API_SUB_URL).request.get(
        `${path}`,
        path
    );
};
