import Moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useIntl} from 'react-intl';
import logApi from '../../../../../api/logging/log.api';
import {
  logCallListingClick,
  logCallListingSuccess,
  logFavoriteAddClick,
  logFavoriteRemoveClick,
  logSendMessage,
  logStatsListingPrint,
  logViewCvClickAsListing,
  logViewListingClick,
} from '../../../../../logging/helpers/commonLoggingFunctions';
import {toAbsoluteUrl} from '../../../../helpers';
import {checkForListingIdInCookiesIfExist, modifyFormat} from '../../../../helpers/LastContacted';
import {
  defaultOptions,
  generateOpenSooqRedirectUrl,
  getCookies,
  getImageUrl,
  getNoImageLogo,
  isDeviceMobile,
  redirectionInterceptor,
  setCookie,
  showChatInCountry,
} from '../../../../helpers/commonFunctions';
import {useLang} from '../../../../i18n/OpenSooqi18n';
import IconVerified from '../../../../modules/common/IconVerified';
import {JOB_POSTER_TYPE, JOB_SEEKER_TYPE} from '../../../../modules/listing/my-listings/helper';
import LastContacted from '../LastContacted';
import {EnumElementName} from '../../../../../logging/types/LoggingTypes';
import getListingPhoneV3Api from '../../../../../api/listing/getListingPhoneV3.api';
import styles from './RecentlyViewedListingCell.module.css';
import ListingsChatPopUp from '../../../../modules/ListingsChatPopUp/ListingsChatPopUp';
import {
  RecentlyViewedListing,
  RecentlyViewedListingsInterface,
} from '../../../../interfaces/RecentlyViewedListingsInterface';

interface CellProps {
  ad: RecentlyViewedListing;
  listingData: RecentlyViewedListingsInterface;
  action: (params: any) => any;
  screen?: string;
  dataIdSource: string;
  index: number;
}

const RecentlyViewedListingCell: React.FC<CellProps> = ({
  ad,
  action,
  screen = 'CallBtn_PostCell_MyRecentlyViewedScreen',
  dataIdSource,
  index,
  listingData,
}) => {
  const memberInfo = getCookies().userInfo;
  const [phone, setPhone] = useState(ad.phone_number);
  const Intl = useIntl();
  const lang = useLang();
  const cellRef = useRef();
  const [whatsPhone, setWhatsPhone] = useState<string>(null);
  const [smsPhone, setSmsPhone] = useState<string>(null);
  const [showLastContacted, setShowLastContacted] = useState<boolean>(false);
  const [lastContacted, setLastContacted] = useState<any>(null);
  const isJobs = ad?.result_type == JOB_POSTER_TYPE || ad?.result_type == JOB_SEEKER_TYPE;
  const cityLabel = lang === 'ar' ? ad?.city_label : ad?.cityEnName;
  const neighborhoodLabel = lang === 'ar' ? ad?.nhood_label : ad?.nhood_reporting;
  const categoryLabel = lang === 'ar' ? ad?.cat1_label : ad?.cat1_code;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          let timeout = index * 100 + 200;
          setTimeout(() => {
            logStatsListingPrint({
              id: ad.id,
              category: {label: ad.cat1_code},
              subCategory: ad.cat2_code,
              service:
                  ad.post_badges && ad.post_badges.length > 0
                      ? ad.post_badges[ad.post_badges.length - 1]
                      : '',
              city: {
                name: ad.city_reporting,
              },
              neighborhoodName: ad.nhood_reporting ? ad.nhood_reporting : '',
              listingStatus: ad?.listing_status,
              memberId: ad?.member_id,
              userTargetType: ad?.user_target_type
            });
            observer.disconnect();
          }, timeout);
        }
      },
      {threshold: 0.1}
    );

    if (cellRef.current) {
      observer.observe(cellRef.current);
    }
    Moment.locale('en');

    return () => {
      if (cellRef.current) observer.unobserve(cellRef.current);
    };
  }, []);

  const showPhoneNumber = async () => {
    try {
      await logApi('call', 'post', ad.id, screen);

      const isDifferentMember =
        ad.phone_number &&
        getCookies().userInfo?.id?.toString() !== ad?.member_id?.toString();

      if (isDifferentMember) {
        const {data} = await getListingPhoneV3Api({
          reveal_key: ad.phone_reveal_key,
          type: 'post',
        });

        setPhone(data.ui_phone_number);
        setWhatsPhone(data.whatsapp_phone_number);
        setSmsPhone(data.sms_phone_number);

        const loggingData = {
          id: ad.id,
          category: {label: ad.cat1_code},
          subCategory: ad.cat2_code,
          service:
              ad.post_badges && ad.post_badges.length > 0
                  ? ad.post_badges[ad.post_badges.length - 1]
                  : '',
          city: {
            name: ad.city_reporting,
          },
          neighborhoodName: ad.nhood_reporting ? ad.nhood_reporting : '',
          listingStatus: ad?.listing_status,
          memberId: ad?.member_id,
          userTargetType: ad?.user_target_type
        };

        logCallListingClick(loggingData, 'Yes');
        window.location.href = `tel:${data.call_phone_number}`;
        logCallListingSuccess(loggingData, 'Yes', 200);
        logSendMessage(loggingData, getCookies().userInfo?.id === ad.member_id, 500);
      }
    } catch (error) {
      console.log('Error showing phone details:', error);
    }
  };

  const customParamsList = () => {
    return ad?.highlight_list?.map((customParam: string, index: number) => {
      const arrayCounter = ad?.highlight_list?.length;
      const counterTocCheck = arrayCounter < 3 ? arrayCounter : 3;

      return (
        index < counterTocCheck && (
          <span key={index} className='bigContainer'>
            {customParam.trim()}
            {index < counterTocCheck - 1 ? ', ' : ''}
          </span>
        )
      );
    });
  };

  const cookies = getCookies();
  const redirectToPost = (event: any, post: RecentlyViewedListing): void => {
    redirectionInterceptor();

    if (!event.target.classList.contains('bigContainer')) return;

    const generateRedirectLink = (): string => {
      const baseUrl = process.env.REACT_APP_OPENSOOQ_BASE ?? '';
      const country = cookies.ecountry ?? '';
      const redirectUrl = generateOpenSooqRedirectUrl(baseUrl, country, lang);

      switch (post.result_type) {
        case JOB_POSTER_TYPE:
          return `${redirectUrl}/job-posters/${post.id}`;
        case JOB_SEEKER_TYPE:
          return `${redirectUrl}/job-seekers/${post.cv_id}`;
        default:
          return post?.post_url;
      }
    };

    const logPostView = (): void => {
      const postLoggingData = {
        id: ad.id,
        category: {label: ad.cat1_code},
        subCategory: ad.cat2_code,
        service:
            ad.post_badges && ad.post_badges.length > 0
                ? ad.post_badges[ad.post_badges.length - 1]
                : '',
        city: {
          name: ad.city_reporting,
        },
        neighborhoodName: ad.nhood_reporting ? ad.nhood_reporting : '',
        listingStatus: ad?.listing_status,
        memberId: ad?.member_id,
        userTargetType: ad?.user_target_type
      };

      if (post.cat2_code === 'JobSeeker') {
        logViewCvClickAsListing(postLoggingData);
      } else {
        logViewListingClick(postLoggingData, EnumElementName.CARD_CELL_RESULT);
      }
    };

    const link = generateRedirectLink();
    logPostView();

    const cleanedLink = link.replace('/ar/ar/', '/ar/').replace('/en/en/', '/en/');
    window.open(cleanedLink, '_blank');
  };

  useEffect(() => {
    if (
      getCookies().lastContacted &&
      getCookies()?.lastContacted.filter(
        (item: {post_id: string | number}) => +item.post_id == ad.id
      ).length > 0
    ) {
      let thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
      if (
        new Date(
          getCookies()?.lastContacted?.filter(
            (item: {post_id: string | number}) => +item.post_id == ad.id
          )[0].last_contacted
        ) > thirtyDaysFromNow
      ) {
        defaultOptions.expires = thirtyDaysFromNow.toString();
        setCookie(
          'lastContacted',
          JSON.stringify(
            getCookies().lastContacted?.filter(
              (item: {post_id: string | number}) => +item.post_id != ad.id
            )
          ),
          defaultOptions
        );
      } else {
        setShowLastContacted(true);
        setLastContacted(modifyFormat(getCookies().lastContacted, ad.id, lang));
      }
    }
  }, [getCookies()?.lastContacted]);

  const baseImageUrl = listingData?.config?.images_cdn;
  const imageUri = ad?.image_uri;
  const crop = '640x480';
  const userImageCorp = '220x0';

  const generateImageSourceForBranding = () => {
    const defaultAvatarUrl = `${listingData?.config?.default_image_url}${listingData?.config?.default_avatar}`;
    const defaultShopAvatarUrl = `${listingData?.config?.default_image_url}${listingData?.config?.default_shop_avatar}`;

    if (ad?.branding?.avatar) {
      const regex = /\/previews\/[^\/]+\/(.*)/;
      const match = ad.branding.avatar.match(regex);

      if (match && match[1]) {
        return defaultAvatarUrl;
      }

      return getImageUrl(baseImageUrl, ad?.branding?.avatar, userImageCorp);
    }

    if ((ad?.branding?.avatar === null || ad?.branding?.avatar === "") && ad?.member_badges?.includes("shop")) {
      return defaultShopAvatarUrl;
    }

    return defaultAvatarUrl;
  };


  const chatRoomData = {
    postId: ad?.id,
    ownerId: ad?.member_id,
    userId: memberInfo?.id,
    post_img: `${getImageUrl(baseImageUrl, imageUri, userImageCorp)}`,
    post_title: ad.title,
    room: {
      avatar: null,
      name: ad.member_display_name,
    },
  };

  const getPostBadgesIcons = (postBadge) => {
    const icons = {
      turbo: '/media/custom/turbo.svg',
      vip: '/media/custom/VIP-service.svg',
      featured: ad?.post_badges?.includes('turbo') ? null : '/media/custom/featured-services.svg',
      Bumpup: '/media/custom/Bumpup-services.svg',
      can_export: '/media/custom/iconExport.svg',
    };

    return icons[postBadge] || null;
  };

  return (
    <div
      ref={cellRef}
      id='recentlyViewedListingCell'
      key={index}
      className={`mb-32 position-relative height-100 customShadow grayHoverBg hoverPointer bigContainer`}
      onClick={(e) => {
        redirectToPost(e, ad);
      }}
      data-id={`${dataIdSource}-listings-list-${ad.id}`}
    >
      <div
        className={`flex flexNoWrap ps-4 pe-4 pt-5 pb-4 blackColor radius-8 bigContainer ripple boxShadow2`}
      >
        <div
          className={`sc-265a2526-1 jRUVEn postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ixKSmm`}
        >
          <img src={
            isJobs && ad?.member_avatar_uri
                ? getImageUrl(baseImageUrl, ad?.member_avatar_uri, crop)
                : isJobs && !ad?.member_avatar_uri
                    ? getNoImageLogo(lang)
                    : ad?.image_uri
                        ? getImageUrl(baseImageUrl, ad?.image_uri, crop)
                        : getNoImageLogo(lang)
          }
               width='272'
            height='204'
            className={`width-100 bigContainer customImg height-100`}
            alt={ad.title}
            loading='eager'
          />
        </div>
        <div className='sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn'>
          <div className='flex flexSpaceBetween'>
            <span
              className={` ${
                isMobile ? 'fs-5' : 'fs-2'
              } fw-bold  breakWord bigContainer overflowHidden  `}
              data-id={`${dataIdSource}-listings-list-postTitle-${ad.id}`}
            >
              {ad.title}
            </span>
          </div>
          <div className={`${isMobile ? 'fs-5' : 'fs-3'} bigContainer`}>{customParamsList()}</div>
          <div className='flex alignItems bigContainer '>
            <div className={`${isMobile ? 'fs-5' : 'fs-3'} category`}>
              {categoryLabel ? categoryLabel + ` ${Intl.formatMessage({id: 'in'})}` : ''}{' '}
              {neighborhoodLabel ? (
                <span className='text-dark fw-bold'>
                  <b>{neighborhoodLabel}</b>
                </span>
              ) : (
                <span className='text-dark fw-bold'>
                  <b>{cityLabel}</b>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center gap-3 ps-4 pe-4 pt-2 pb-1 bigContainer'>
        <div className='pe-1 bd-highlight bigContainer'>
          <div className='sc-265a2526-2 iRqeKu numOfCont flex bigContainer alignItems radius-8 whiteColor fs-13 gap-5'>
            <span
              className='d-flex alignItems radius-8 bigContainer'
              style={{backgroundColor: '#00000091'}}
            >
              <svg
                viewBox='0 0 12 12'
                width='12'
                height='12'
                className='me-2 bigContainer'
                data-name='iconCamera2'
              >
                <path
                  fill='#fff'
                  d='M12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667ZM3.66667 7L5.33333 9.00667L7.66667 6L10.6667 10H1.33333L3.66667 7Z'
                ></path>
              </svg>
              {ad?.image_count}
            </span>
          </div>
        </div>
        <div className='ms-1 bd-highlight bigContainer'>
          <div className='d-flex justify-content-start bigContainer gap-1'>
            {ad?.member_display_name ? (
              <img
                className='bigContainer me-1'
                width='25'
                height='25'
                src={toAbsoluteUrl(`/media/custom/delivery.svg`)}
                alt='delivery icon'
              />
            ) : null}
            {ad?.post_badges
              ?.filter((services) => getPostBadgesIcons(services))
              ?.map((services, index) => {
                return (
                  <img
                    key={index}
                    className='bigContainer me-1'
                    width='25'
                    height='25'
                    src={toAbsoluteUrl(getPostBadgesIcons(services))}
                    alt='badge'
                  />
                );
              })}
          </div>
        </div>
        {ad?.result_type != JOB_POSTER_TYPE && ad.result_type != JOB_SEEKER_TYPE && (
          <div className='postPrice ms-auto bold alignSelfCenter fs-3'>
            {ad.price_amount ? (
              <span className='fw-bolder'>{`${ad.price_amount} ${ad.localized_currency}`}</span>
            ) : (
              Intl.formatMessage({id: 'noPrice'})
            )}
          </div>
        )}
      </div>
      <div
        className={`d-flex align-items-center pe-4 pb-5 gap-1 bigContainer pt-5 ${
          isDeviceMobile() && isJobs ? 'ps-3' : isDeviceMobile() && !isJobs ? 'ps-2' : 'ps-4'
        }`}
        data-id={`${dataIdSource}-listings-list-CTAsBlock-${ad.id}`}
        id='postItemButtons'
      >
        {ad.phone_number !== '' &&
          ad.cat2_code != JOB_POSTER_TYPE &&
          ad.cat2_code != JOB_SEEKER_TYPE && (
            <div className={`pe-1 bd-highlight`}>
              <button
                data-id={`${dataIdSource}-listings-list-callBtn-${ad?.id}`}
                onClick={() => {
                  showPhoneNumber();
                  checkForListingIdInCookiesIfExist(ad, null);
                }}
                style={{minWidth: '135px', height: '39.5px'}}
                className={`d-flex align-items-center  justify-content-center gap-2 ${
                  !ad.has_phone  ? styles.btnGray : styles.btnBlue
                } ${isMobile ? 'flex-1' : ''}`}
              >
                <span
                  className={`fas fa-phone me-1 ${lang == 'en' ? 'mt-1' : ''}`}
                  style={{
                    color: !ad.has_phone? '#A1A1A1' : '#0179FF',
                  }}
                ></span>
                <span className='mt-1'>{phone != 'XX' && phone != '' ? phone : ''} </span>
              </button>
            </div>
          )}
        {showChatInCountry() &&
          ad?.member_id != memberInfo?.id &&
          ad.cat2_code != JOB_POSTER_TYPE &&
          ad.cat2_code != JOB_SEEKER_TYPE && (
            <div
              className='pe-1 bd-highlight'
              data-id={`${dataIdSource}-listings-list-chatBtn-${ad.id}`}
            >
              <ListingsChatPopUp
                setShowLastContacted={setShowLastContacted}
                post_url={ad?.post_url}
                roomData={chatRoomData}
                contactAddon={ad.addons}
                listing={ad}
                whatsPhone={whatsPhone}
                smsPhone={smsPhone}
                widthValue='103px'
                enableChatByDefault={true}
              />
            </div>
          )}
        <div className='bd-highlight fs-6'>
          <button
            data-id={`${dataIdSource}-listings-list-favoriteBtn-${ad.id}`}
            className={`bg-white btn-sm ${styles.favBtn} p-2 rounded`}
            onClick={(e) => {
              e.stopPropagation();
              if (ad?.bookmark_status) {
                logFavoriteRemoveClick({
                    id: ad.id,
                    category: {label: ad.cat1_code},
                    subCategory: ad.cat2_code,
                    service: ad.post_badges && ad.post_badges.length > 0 ? ad.post_badges[ad.post_badges.length - 1] : '',
                    city: {
                      name: ad.city_reporting,
                    },
                    neighborhoodName: ad.nhood_reporting ? ad.nhood_reporting : '',
                    listingStatus: ad?.listing_status,
                    memberId: ad?.member_id,
                    userTargetType: ad?.user_target_type
                  },
                  'Yes'
                );

                logFavoriteAddClick({
                    id: ad.id,
                    category: {label: ad.cat1_code},
                    subCategory: ad.cat2_code,
                    service: ad.post_badges && ad.post_badges.length > 0 ? ad.post_badges[ad.post_badges.length - 1] : '',
                    city: {
                      name: ad.city_reporting,
                    },
                    neighborhoodName: ad.nhood_reporting ? ad.nhood_reporting : '',
                    listingStatus: ad?.listing_status,
                    memberId: ad?.member_id,
                    userTargetType: ad?.user_target_type
                  },
                  'Yes',
                  0
                );
              }
              action(ad.id);
            }}
          >
            {ad.bookmark_status ? (
              <svg
                viewBox='0 0 32 32'
                width='25'
                height='25'
                data-name='iconFavouriteFill'
                className='bigContainer'
              >
                <path
                  fill='#ee5555'
                  stroke='#ee5555'
                  strokeWidth='3'
                  d='M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z'
                ></path>
              </svg>
            ) : (
              <svg
                viewBox='0 0 32 32'
                width='25'
                height='25'
                data-name='iconFavouriteFill'
                className='bigContainer'
              >
                <path
                  fill='#fff'
                  stroke='#ee5555'
                  strokeWidth='3'
                  d='M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z'
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>
      {ad && ad?.branding && (
          <div className='ps-5 pe-5 bigContainer'>
          <div className='separator separator-dotted border-gray mb-4 mt-4 bigContainer'></div>
        </div>
      )}
      {ad && ad?.branding && (
        <div className='d-flex align-items-center ps-4 pe-4 pt-1 pb-3 bigContainer'>
          <div className='d-flex align-items-center flex-grow-1 bigContainer'>
            <div className='symbol symbol-45px me-5 bigContainer'>
              <img
                className='bigContainer'
                src={generateImageSourceForBranding()}
                alt='userAvatarImage'
              />
            </div>
            <div className='d-flex flex-column bigContainer'>
              <span
                className={`text-gray-800 fs-6 fw-bold text-truncate ${
                  isDeviceMobile() ? 'w-200px' : 'w-600px'
                } bigContainer`}
              >
                {ad.branding?.name}
              </span>
            </div>
          </div>
          {ad?.member_badges?.includes("authorized_seller") ? (
            <div className='my-0 border rounded-1 border-1 verifiedBorderColor bigContainer'>
              <div className='d-flex align-items-center flex-grow-1 me-1 p-1 bigContainer'>
                <div className='symbol symbol-25px pe-1 bigContainer'>
                  <IconVerified className='bigContainer' />
                </div>
                <div className='d-flex flex-column bigContainer'>
                  <span className='text-gray-900 fs-7 fw-bolder verifiedTextColor bigContainer'>
                    {Intl.formatMessage({id: 'verified'})}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      {((getCookies().lastContacted &&
        getCookies().lastContacted.length > 0 &&
        getCookies().lastContacted.filter(
          (item: {post_id: string | number}) => +item.post_id == ad.id
        ).length > 0) ||
        showLastContacted) && <LastContacted listingId={ad.id} lastContacted={lastContacted} />}
    </div>
  );
};

export default RecentlyViewedListingCell;
